import { Link } from "react-router-dom";

export const IntroVideo = ({ canSkip, onEnded, skipUrl }) => {
	return (
		<section className="video js-section section">
			<div className="video-cnt js-video">
				<video
					id="video-page"
					autoPlay
					muted
					playsInline
					onEnded={onEnded}
				>
					<source src="/video/intro.mp4" type="video/mp4" />
				</video>
			</div>
			{canSkip && <div className="video-btns">
				<Link className="video-btn btn js-skip" to={skipUrl}>
					{" "}
					Skip
				</Link>
			</div>}
		</section>
	);
}