import { Timer } from "timer-node";
import Transcript from './Transcript';

// the "prompt" is the thing at the top of the screen.
// its appearance is a function of the time since the user first touched the home base for the current question.
// it can be a "rule", which is a string that is one of 'shape', 'size', or 'color'.
// or it can be a "shape", which is circle or triangle of a given size and color.
// or it can be 'none'.
//
// the prompt's lifecycle is: none (rcRuleDelay), rule (rcRuleTime), none (random), shape (until target touch)
//
// the user must be touching the base when the prompt becomes a shape,
// since that is the beginning of the time interval that the user's score is based on.
// if the user has touched the home base, which started the prompt lifecycle, but is not touching
// at the moment the prompt should become a shape, the lifecycle is re-started from the beginning.

const rcRuleDelay = 400;      // time from home-base touch intil rule appears
const rcRuleTime = 1000;      // time to display the rule
const rcMinShapeDelay = 350;  // min/max values for the delay betweem the rule disappearing and the shape appearing
const rcMaxShapeDelay = 1500;

function rcRandomShapeDelay() {
	return rcMinShapeDelay + Math.random() * (rcMaxShapeDelay - rcMinShapeDelay);
}

export default class RuleChangeController {
	questionIndex = 0;
	userIsTouchingBase = false;

	constructor(rules, questions, setState = () => { }) {
		this.rules = rules;
		this.questions = questions;
		this.setState = setState;

		// baseTimer starts from zero when the user touches home base for each question
		this.baseTimer = new Timer({ label: "base-timer" });

		// drillTimer starts when the drill starts, runs continuously, and is used only for transcripting
		this.drillTimer = new Timer({ label: "drill-timer" });

		this.transcript = new Transcript();
		this.transcript.LEFT = Transcript.LEFT;
		this.transcript.RIGHT = Transcript.RIGHT;
	}

	baseTimerStart() {
		this.baseTimer.clear();
		this.shapeDelay = rcRandomShapeDelay();
		this.baseTimer.start();

		var me = this;

		// lifecycle of the prompt - a sequence of timeouts, each of which sets the promptType
		me.setState({ promptType: 'none' });
		me.timeoutId = setTimeout(function () {
			me.setState({ promptType: 'rule' });
			me.timeoutId = setTimeout(function () {
				me.setState({ promptType: 'none' });
				me.timeoutId = setTimeout(function () {
					// if the user is not touching home base the moment the prompt becomes a shape, restart this question.
					if (me.userIsTouchingBase)
						me.setState({ promptType: 'shape' })
					else
						me.baseTimerClear();
				}, (rcRuleDelay + rcRuleTime + me.shapeDelay) - me.baseTimer.ms());
			}, (rcRuleDelay + rcRuleTime) - me.baseTimer.ms());
		}, rcRuleDelay);
	}

	baseTimerClear() {
		if (this.hasOwnProperty("timeoutId"))
			clearTimeout(this.timeoutId);
		this.baseTimer.clear();
		this.setState({ promptType: 'none' });
	}

	nextQuestion() {
		this.baseTimerClear();
		this.questionIndex++;
		this.setState({ questionIndex: this.questionIndex });
	}

	start(leftHomebasePosition, rightHomebasePosition) {
		this.transcript.reset();
		this.transcript.saveDrillInfo("rule-change", "0.2.0");
		this.drillTimer.start();

		this.transcript.saveHomeBase(
			Transcript.CENTER,
			leftHomebasePosition,
			rightHomebasePosition,
			"Home"
		);
	}

	targetShown(clientX, clientY, question) {
		this.transcript.saveTarget(
			this.questionIndex,
			clientX,
			clientY,
			question.correctIndex,
			this.drillTimer.ms()
		);
	}

	targetClicked(clientX, clientY, correctIndex) {
		this.baseTimerClear();

		this.transcript.saveTouchStart(
			Transcript.DISMISSES_TARGET,
			correctIndex,
			clientX,
			clientY,
			this.drillTimer.ms()
		);
	}

	baseHeld(clientX, clientY) {
		this.userIsTouchingBase = true;

		if (!this.baseTimer.isRunning())
			this.baseTimerStart();

		this.transcript.saveTouchStart(
			Transcript.ACTIVATES_HOME_BASE,
			Transcript.CENTER,
			clientX,
			clientY,
			this.drillTimer.ms()
		);
	}

	baseReleased(clientX, clientY) {
		this.userIsTouchingBase = false;

		this.transcript.saveTouchEnd(
			Transcript.DEACTIVATES_HOME_BASE,
			Transcript.CENTER,
			clientX,
			clientY,
			this.drillTimer.ms()
		);
	}

	getCurrent() {
		return {
			rule: this.rules[this.questionIndex],
			question: this.questions[this.questionIndex]
		};
	}

	isFinished() {
		return this.questionIndex > this.rules.length - 1;
	}

	stop() {
		this.baseTimerClear();
		this.drillTimer.clear();
	}
}
