import * as myxhr from "./myxhr.js";

/*
/responses - save survey responses
Sample request body
    {
        “responses”: [{
            “item”: “rfxnBirthDate”,
            “valString”: “2001-02-01”
        },
        {
            “item”: “rfxnBirthSex”,
            “valString”: “female”
        },
        {
            “item”: “rfxnSport1”,
            “valString”: “football - american”
        },
        {
            “item”: “rfxnLevel1”,
            “valString”: “high school”
        },
        {
            “item”: “rfxnSubLevel1”,
            “valString”: “varsity”
        },
        {
            “item”: “rfxnSchool1”,
            “valString”: “High School Name”
        },
        {
            “item”: “rfxnSchoolZip1”,
            “valString”: “46817”
        },
            “item”: “rfxnTeam1”,
            “valString”: “Rockets”
        {
            “item”: “rfxnPos1”,
            “valString”: “safety”
        }
        ]
    }
*/

export function post(responses) {
    const request = {
        responses: Object.keys(responses).map(key => ({
            item: key,
            valString: responses[key]
        }))
    };
    return myxhr.post('/responses', request);
}

export function get() {
    return myxhr.get('/responses');
}