export const Desktop = () => {
	return (
		<div className="desktop js-desktop">
			<div className="wrapper">
				<h2 className="desktop-txt">
					please access this app <br /> from <span>your phone or tablet</span>
				</h2>
				<img style={{ margin: "20px" }} src="/images/qrcode.png" alt="" />
			</div>
		</div>
	);
};
