import * as myxhr from "./myxhr.js";

export function put(body) {
    return myxhr.put('/account', body);
}

export function get() {
    return myxhr.get('/account');
}

export function remove() {
    return myxhr.del('/account');
}