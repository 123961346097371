import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import api from "../api";
import { PasswordChanged } from "../components/PasswordChanged";
import { checkGetStateCalled } from '../globalState';

export const EmailVerified = () => {
    const [error, setError] = useState("");
    const [done, setDone] = useState(false);

    const pass1Ref = useRef(null);
    const pass2Ref = useRef(null);

    const search = useLocation().search;
    const token = new URLSearchParams(search).get('token');

    useEffect(() => {
        checkGetStateCalled();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const password1 = pass1Ref.current.value;
        const password2 = pass2Ref.current.value;

        if (password1 !== password2) {
            setError("passwords don't match");
            return;
        }

        if (password1 === "") {
            setError("password can't be empty");
            return;
        }

        setError("");

        api.account.put({ password: password1, token: token })
            .then(() => setDone(true))
            .catch(err => setError(err.response.data));
    };

    if (done)
        return <PasswordChanged />;

    return (
        <>
            <main className="main show-on-desktop">
                <section className="form-section js-section section">
                    <div className="wrapper">
                        <form className="form">
                            <div className="form-inner">
                                <h2 className="form-title">Your Email has been verified</h2>
                                <div className="form-descr">
                                    Please create a password to finalize your account setup
                                </div>
                                <div class="form-wrap">
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="password"
                                            id="password1"
                                            placeholder="password"
                                            ref={pass1Ref}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="password"
                                            id="password2"
                                            placeholder="confirm password"
                                            ref={pass2Ref}
                                        />
                                    </div>
                                    {error && <div className="form-descr" style={{ marginTop: '10px', color: '#DC0019' }}>
                                        Error: {error}
                                    </div>}
                                    <div class="form-group form-group--btn">
                                        <button className="form-btn btn" onClick={handleSubmit}>
                                            Continue
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </main>
        </>
    );
}
