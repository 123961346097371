// RULE CHANGE QUESTIONS
//
// a user's response time is affected by whether or not the rule has changed since
// the previous target and by the "incongruence" - how many attributes in which
// the target differs from the example that it matches.
// we want to vary these randomly each run, but keep the same overall distribution
// for each run, so all runs have the same difficulty.
import Transcript from "../controllers/Transcript";

function shuffleArray(array) {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		const temp = array[i];
		array[i] = array[j];
		array[j] = temp;
	}
	return array;
}

// to randomize rule-changed vs rule-stayed-the-same but keep the same distribution
// for every run, we use a fixed set of rule sequences but shuffle them.
// they will later be interleaved; if they weren't interleaved, adjacent sequences
// of the same rule would coalesce in the user's experience.
export const questionTypes = shuffleArray([
	shuffleArray([[0], [0, 0], [0, 0, 0]]), // size
	shuffleArray([[1], [1, 1], [1, 1, 1]]), // color
	shuffleArray([[2], [2, 2], [2, 2, 2]]), // shape
]);

export const questions = [];

// interleave the shuffled sequences and linearize into a single array of numbers
// whose values are 0, 1, or 2, corresponding to size, color, or shape
for (let i = 0; i <= 2; i++) {
	for (const val of questionTypes[0][i]) {
		questions.push(val);
	}
	for (const val of questionTypes[1][i]) {
		questions.push(val);
	}
	for (const val of questionTypes[2][i]) {
		questions.push(val);
	}
}

// now create and shuffle equal numbers of combinations of left/right and incongruence
const responsesAndAttributes = shuffleArray([ // first index will be correctResponse, second index will be incongruenceDistance, third index will be attributeChange
	[0, 0, 0],
	[0, 0, 0],
	[0, 1, 1],
	[0, 1, 1],
	[0, 1, 2],
	[0, 1, 2],
	[0, 2, 0],
	[0, 2, 0],
	[0, 2, 0],
	[1, 0, 0],
	[1, 0, 0],
	[1, 1, 1],
	[1, 1, 1],
	[1, 1, 2],
	[1, 1, 2],
	[1, 2, 0],
	[1, 2, 0],
	[1, 2, 0]
]);

const correctResponse = []; // 0 = left, 1 = right
const incongruenceDistance = []; // 0 = prompt matches one target or the other, 1 = one attribute is different from correct response, 2 = two attributes are different from correct response
const attributeChange = []; // 0 = nothing, 1 = one step in array position determines which attribute changes, 2 = two steps in array position determines which attribute changes

// extract columns of responsesAndAttributes for code simplicity and readability below
for (let i = 0; i < responsesAndAttributes.length; i++) {
	correctResponse.push(responsesAndAttributes[i][0]);
	incongruenceDistance.push(responsesAndAttributes[i][1]);
	attributeChange.push(responsesAndAttributes[i][2]);
}

const attributes = [
	['small', 'large'],
	['blue', 'yellow'],
	['triangle', 'circle'],
];

export const realQuestions = [];

for (let i = 0; i < correctResponse.length; i++) {
	const currentPrompt = [correctResponse[i], correctResponse[i], correctResponse[i]];

	// flip 0 to 1 or 1 to 0 for appropriate attributes
	if (incongruenceDistance[i] === 1) {
		currentPrompt[(questions[i] + attributeChange[i]) % 3] = (currentPrompt[(questions[i] + attributeChange[i]) % 3] + 1) % 2;
	} else if (incongruenceDistance[i] === 2) {
		currentPrompt[(questions[i] + 1) % 3] = (currentPrompt[(questions[i] + 1) % 3] + 1) % 2;
		currentPrompt[(questions[i] + 2) % 3] = (currentPrompt[(questions[i] + 2) % 3] + 1) % 2;
	}

	// build object with necessary details for exporting to DrillRuleChange.jsx
	let correctOption = {
		shape: attributes[2][currentPrompt[2]],
		correct: true,
		size: attributes[0][currentPrompt[0]],
		color: attributes[1][currentPrompt[1]],
		correctIndex: correctResponse[i] ? Transcript.RIGHT : Transcript.LEFT,
	};

	// save objects into exported array
	realQuestions.push(correctOption);

	// correct the numerical value of questions back to strings for exporting to DrillRuleChange.jsx
	if (questions[i] === 0) {
		questions[i] = "size"
	} else if (questions[i] === 1) {
		questions[i] = "color"
	} else {
		questions[i] = "shape"
	}
}
