import {
	BrowserRouter as Router,
	Switch,
	Route
} from "react-router-dom";

import { Index } from './pages/Index';
import { Intro } from './pages/Intro';
import { Drills } from './pages/Drills';
import DrillGoNoGoIntro from "./pages/DrillGoNoGoIntro";
import DrillReactionIntro from "./pages/DrillReactionIntro";
import DrillMinefieldIntro from "./pages/DrillMinefieldIntro";
import DrillRuleChangeIntro from "./pages/DrillRuleChangeIntro";
import { DrillCompleted } from "./pages/DrillCompleted";
import { Home } from "./pages/Home";
import { Training } from "./pages/Training";
import ResultMinefield from "./pages/ResultMinefield";
import { CreateAccount } from "./pages/CreateAccount";
import { AdditionalInfo } from "./pages/AdditionalInfo";
import { RedirectOnNoCookie } from "./components/RedirectOnNoCookie";
import { EmailVerified } from "./pages/EmailVerified";
import { Login } from "./pages/Login";
import { ForgotPassword } from "./pages/ForgotPassword";
import { Profile } from "./pages/Profile";
import { EmailChanged } from "./pages/EmailChanged";
import { ResetPassword } from "./pages/ResetPassword";
import { Player } from "./pages/Player";
import { SocialMedia } from "./pages/SocialMedia";
import usePageTracking from "./usePageTracking";

//
// NOTE: every route except "/" must begin with "/a/" or "/d/",
// otherwise if a user bookmarked it, they would get an error when they tried to visit it.
//

const PageTracking = () => {
	usePageTracking();
	return <div></div>;
}

function App() {
	return (
		<Router>
			<PageTracking />
			<Switch>
				<Route path="/a/intro">
					<Intro />
				</Route>
				<Route path="/a/drills-go-no-go">
					<RedirectOnNoCookie>
						<DrillGoNoGoIntro retake={false} />
					</RedirectOnNoCookie>
				</Route>
				<Route path="/a/drills-reaction">
					<RedirectOnNoCookie>
						<DrillReactionIntro retake={false} />
					</RedirectOnNoCookie>
				</Route>
				<Route path="/a/drills-minefield">
					<RedirectOnNoCookie>
						<DrillMinefieldIntro retake={false} />
					</RedirectOnNoCookie>
				</Route>
				<Route path="/a/drills-rule-change">
					<RedirectOnNoCookie>
						<DrillRuleChangeIntro retake={false} />
					</RedirectOnNoCookie>
				</Route>
				<Route path="/a/drills">
					<Drills />
				</Route>
				<Route path="/d/drills-go-no-go">
					<RedirectOnNoCookie>
						<DrillGoNoGoIntro retake={true} />
					</RedirectOnNoCookie>
				</Route>
				<Route path="/d/drills-reaction">
					<RedirectOnNoCookie>
						<DrillReactionIntro retake={true} />
					</RedirectOnNoCookie>
				</Route>
				<Route path="/d/drills-minefield">
					<RedirectOnNoCookie>
						<DrillMinefieldIntro retake={true} />
					</RedirectOnNoCookie>
				</Route>
				<Route path="/d/drills-rule-change">
					<RedirectOnNoCookie>
						<DrillRuleChangeIntro retake={true} />
					</RedirectOnNoCookie>
				</Route>
				<Route path="/a/drill-completed">
					<RedirectOnNoCookie>
						<DrillCompleted />
					</RedirectOnNoCookie>
				</Route>
				<Route path="/d/home">
					<RedirectOnNoCookie>
						<Home />
					</RedirectOnNoCookie>
				</Route>
				<Route path="/d/training">
					<RedirectOnNoCookie>
						<Training />
					</RedirectOnNoCookie>
				</Route>
				<Route path="/a/result-minefield">
					<RedirectOnNoCookie>
						<ResultMinefield />
					</RedirectOnNoCookie>
				</Route>
				<Route path="/d/create-account">
					<CreateAccount />
				</Route>
				<Route path="/d/additional-information">
					<AdditionalInfo />
				</Route>
				<Route path="/d/email-verified">
					<EmailVerified />
				</Route>
				<Route path="/d/email-changed">
					<EmailChanged />
				</Route>
				<Route path="/d/login">
					<Login />
				</Route>
				<Route path="/d/forgot-password">
					<ForgotPassword />
				</Route>
				<Route path="/d/reset-password">
					<ResetPassword />
				</Route>
				<Route path="/d/profile">
					<Profile />
				</Route>
				<Route path="/d/player">
					<Player />
				</Route>
				<Route path="/d/social-media">
					<SocialMedia />
				</Route>
				<Route path="/">
					<Index />
				</Route>
			</Switch>
		</Router>
	);
}

export default App;
