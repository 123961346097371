import api from './api';
import { getState, haveState, currentState } from './globalState';

//
// the sequence of drills in an assessment and a given user's position in the sequence
// are determined by the back end. this module enables each component of the sequence
// to progress to the next step in the sequence.
//
// the pages in the sequence are:
//
//    /              Index.jsx - landing page, intro video page
//
//    /a/drills      Drills.jsx - initial progress page
//
//    /a/drills-XXX  DrillXXXIntro.jsx - drill intro video page, drill instructions page, drill page, drill completed (progress page)
//
//    /d/home        Home.jsx - where you go immediately after you complete an assessment
//
// the display of some of the elements in the assessment experience are controlled by state flags
// (showVideos, showProgressPage, showInstructions, showSpiderGraph) and by whether or not the
// app was invoked by a B2B customer with ?userSession=TOKEN. these things affect the behavior of
// the individual elements above, but they are completely orthogonal to the sequencing provided by this module.
//


// given a state object and assessment state object, decide what the next step should be.
export const calculateNextStep = (state, assessmentState) => {
	if (assessmentState.todo && assessmentState.todo.length > 0)
		return `/a/drills-${assessmentState.todo[0]}`;

	if (state.accountStatus === "anonymousPerson" && !state.hasRScore) {
		// this can happen in the rare case that a user re-visits the page before they've created an assessment
		return '/a/drills';
	}

	return '/d/home';
};


// asynchronously get state and assessment state, then call calculateNextStep()
export const getNextStep = async () => {
	if (!haveState)
		await getState();
	if (currentState.accountStatus === 'noCookie') {
		// if we are called with no session, something went wrong! we might even be at risk of an infinite loop.
		console.log('ERROR - getNextStep() called with no session');
		return '/';
	}
	const assessmentStateResult = await api.assessmentstate.get();
	return calculateNextStep(currentState, assessmentStateResult.data);
};


// drills call this when they are finished, to post results and determine what to do next
//
// isRetake means the drill was invoked from the training page (NOT by an assessment).
// 
//     
// returned object:
//     result   - if null, skip the result page, if not null, an object containing the first result
//     nextStep - next step in the current assessment (or null if we're not in an assessment)
//     todo     - list of drills left to do in the current assessment (or null if we're not in an assessment)
export const drillCompleted = async (drillName, isRetake, transcript) => {
	if (isRetake) {
		// drill was invoked from training page
		const retakeResult = await api.drill.drillCompleted(drillName, transcript);
		return { result: retakeResult.data.results[0], nextStep: null, todo: [] };
	} else {
		// drill was invoked from an assessment
		const result = await api.assessment.drillCompleted(drillName, transcript);
		return {
			result: null,
			nextStep: calculateNextStep({ accountStatus: "", hasRScore: false }, result.data),
			todo: result.data.todo || []
		};
	}
};
