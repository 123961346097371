import { Header } from "./Header";
import { useHistory } from "react-router-dom";
import { Desktop } from "./Desktop";
import { getState } from "../globalState";

const names = {
	"go-no-go": "Go/No-Go",
	"reaction": "Reaction",
	"minefield": "Minefield",
	"rule-change": "Rule Change"
};

const formatNumber = (n) => {
	return !n ? '' : parseFloat(n).toFixed(2);
}

export const DrillResult = ({ drill, result }) => {
	const history = useHistory();

	const onBackToTraining = async () => {
		const res = await getState();
		const accountStatus = res.accountStatus;
		const lastNagTime = res.lastNagTime;

		const datesAreOnSameDay = (first, second) => {
			console.log(first.getFullYear() + " " + second.getFullYear());
			console.log(first.getMonth() + " " + second.getMonth());
			console.log(first.getDate() + " " + second.getDate());
			return first.getFullYear() === second.getFullYear() &&
				first.getMonth() === second.getMonth() &&
				first.getDate() === second.getDate();
		}


		if (accountStatus === "identifiedPerson" || (lastNagTime != null && datesAreOnSameDay(new Date(lastNagTime), new Date()))) {
			history.push("/d/training");
		} else {
			history.push("/d/create-account");
		}
	};

	return (
		<>
			<Header title={names[drill]} />
			<main className="main">
				<section className="single js-section section">
					<div className="wrapper">
						<h2 className="single-title">{names[drill]}</h2>
						<div className="single-descr" style={{ display: 'none' }}>
							Stopping yourself from reacting to the wrong things
						</div>
						<div className="single-txt">{result.name}:</div>
						<div className="chart chart-full chart--yellow">
							<p className="chart-result chart-result-np">{Math.round(result.value * 100.0) / 100.0} {result.units}</p>
							<div className="chart-wrapp">
								<div className="chart-inner">
									<div className="chart-field" />
									<div className="chart-gradient" />
									<div className="chart-point js-chart-el">
										<div className="chart-point__el" style={{ left: `${formatNumber(result.percentile)}%` }} />
									</div>
									<div className="chart-indicator js-chart-el">
										<div
											className="chart-indicator__el"
											style={{ left: `${formatNumber(result.percentile)}%` }}
										/>
									</div>
									<p className="chart-info">
										Better than <span>{formatNumber(result.percentile)}%</span> of users
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>
				<Desktop />
			</main>
			<footer className="footer">
				<div className="wrapper">
					<div className="footer-btns">
						<button className="footer-btn btn" onClick={onBackToTraining}>
							Back to Training
						</button>
					</div>
				</div>
			</footer>
		</>
	);
};
