import { useRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../api";
import { Desktop } from "../components/Desktop";
import { Header } from "../components/Header";
import { PasswordChanged } from "../components/PasswordChanged";
import { Survey } from "../components/Survey";
import { checkGetStateCalled } from '../globalState';

const s2 = (x) => ("0" + x).slice(-2);

const formatDate = (date) => {
    return `${date.getFullYear()}-${s2(date.getMonth() + 1)}-${s2(date.getDate())}`;
}

export const Profile = () => {
    const history = useHistory();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [fullName, setFullName] = useState("");
    const [birthdate, setBirthdate] = useState("");
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [surveyValues, setSurveyValues] = useState({});
    const [description, setDescription] = useState({});

    const pass1Ref = useRef(null);
    const pass2Ref = useRef(null);

    useEffect(() => {
        checkGetStateCalled();
    }, []);

    useEffect(() => {
        try {
            global.datepicker('.js-datepicker', {
                onSelect: (instance, date) => {
                    console.log(instance, date);
                    if (!date) return;

                    setBirthdate(formatDate(date));
                }
            })
        } catch { }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await api.account.get();
            setFirstName(data.firstName);
            setLastName(data.lastName);
            setFullName(`${data.firstName} ${data.lastName}`);
            setBirthdate(data.birthdate);
            setEmail(data.email);
        };
        fetchData();
    }, []);

    const onSubmit = async (e) => {
        e.preventDefault();

        const password1 = pass1Ref.current.value;
        const password2 = pass2Ref.current.value;

        if (password1 !== password2) {
            setError("passwords don't match");
            return;
        }

        setError("");

        const request = {
            firstName,
            lastName,
            email,
            birthdate,
            password: password1
        }

        await api.responses.post(surveyValues);

        api.account
            .put(request)
            .then(() => {
                setFullName(`${firstName} ${lastName}`);
                setSuccess(true);
                setError("");

                if (password1) {
                    setPasswordChanged(true);
                }
            }).catch(err => {
                setError(err.response.data);
                setSuccess(false);
            });
    }

    const onDeleteAccount = async (e) => {
        e.preventDefault();

        if (!global.confirm("Do you want to delete your account?"))
            return;

        await api.account.remove();
        history.push('/');
    }

    const onSurveyChange = (item, value) => {
        setSurveyValues(prevState => ({
            ...prevState,
            [item]: value
        }));
    }

    const onShowDescription = (question, description) => {
        setDescription({ question, description });
        document.querySelector('.main').classList.add('active-popup');
        document.getElementById('main-pp').classList.add('pp-open');
    }

    const onClose = () => {
        document.querySelector('.main').classList.remove('active-popup');
        document.querySelector('.pp-open').classList.remove('pp-open');
        setDescription({});
    }

    if (passwordChanged) {
        return <PasswordChanged />;
    }

    return (
        <>
            <Header title={`Hi, ${fullName}`} />
            <main className="main">
                <section className="form-section form-profile js-section section">
                    <div className="wrapper">
                        <form className="form">
                            <div className="form-inner">
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="firstName"
                                        placeholder="first name"
                                        value={firstName}
                                        onChange={e => setFirstName(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="lastName"
                                        placeholder="last name"
                                        value={lastName}
                                        onChange={e => setLastName(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="email"
                                        id="email"
                                        placeholder="email"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        className="form-control form-control--date js-datepicker"
                                        type="text"
                                        placeholder="Select Date"
                                        value={birthdate}
                                        id="birthDate"
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="password"
                                        id="password1"
                                        placeholder="password"
                                        ref={pass1Ref}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="password"
                                        id="password2"
                                        placeholder="confirm password"
                                        ref={pass2Ref}
                                    />
                                </div>
                                <Survey onChange={onSurveyChange} onShowDescription={onShowDescription} />
                                {success && <div className="form-group form-links" style={{ marginTop: '10px', color: 'green' }}>
                                    profile successfully updated
                                </div>}
                                {error && <div className="form-group form-links" style={{ marginTop: '10px', color: 'red' }}>
                                    Error: {error}
                                </div>}
                                <div className="form-group form-links">
                                    <a className="form-link form-link--center" href="/" onClick={onDeleteAccount}>
                                        Delete my account{" "}
                                    </a>
                                </div>
                            </div>
                            <div className="form-btns">
                                <div className="form-group">
                                    <button className="btn form-btn" onClick={onSubmit}>
                                        Save Changes
                                    </button>
                                </div>
                                <div className="form-group" style={{ padding: 10 }}>
                                    <a href="/" style={{ margin: "0 auto", color: "#dc0019" }} onClick={e => {
                                        e.preventDefault();
                                        history.goBack();
                                    }}>Cancel</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
                <Desktop />
            </main>
            <div className="popup">
                <div className="popup__wrap">
                    <div className="popup__close" onClick={onClose}><i className="svg-image-times"></i></div>
                    <div className="popup__inner">
                        <div className="popup__tab" id="main-pp">
                            <h2 className="form-title">{description.question}</h2>
                            <p>{description.description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
