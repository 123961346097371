import Lottie from 'react-lottie';

import BubbleBlue from '../animations/Bubble_blue.json';
import BubbleYellow from '../animations/Bubble_yellow.json';
import TriangleBlue from '../animations/Triangle_blue.json';
import TriangleYellow from '../animations/Triangle_yellow.json';

const sizes = {
	small: 64,
	large: 190
}

const shapeFiles = {
	triangle: {
		yellow: TriangleYellow,
		blue: TriangleBlue
	},
	circle: {
		yellow: BubbleYellow,
		blue: BubbleBlue
	},
}

export const ShapeTarget = ({ id, left, right, shape, size, color, onTouchStart, style }) => {
	const shapeSize = sizes[size];

	const shapeStyle = {
		position: "absolute",
		left,
		right,
		top: 0,
		bottom: 0,
		transform: "translateY(0)",
		margin: "auto",
		width: 190,
		height: 190,
		...style
	};

	const animationStyle = {
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		transform: "translateY(0)",
		margin: "auto"
	};

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: shapeFiles[shape][color],
		renderer: 'svg'
	};

	return (
		<div
			id={id}
			className="target"
			onTouchStart={onTouchStart}
			style={shapeStyle}>
			<Lottie
				style={animationStyle}
				options={defaultOptions}
				height={shapeSize}
				width={shapeSize}
				isPaused={false}
				isStopped={false} />
		</div>
	);
};
