import * as myxhr from "./myxhr.js";

/*
    PUT
    /state - user state information
    {
        "seenTrainingIntroVideo": boolean
        "lastNagTime": string 
    }
*/

// NOTE: whenever you call this, you must call getState() to update our internally cached version
export function videoSeen() {
    return myxhr.put('/state', { seenTrainingIntroVideo: true });
}

// NOTE: whenever you call this, you must call getState() to update our internally cached version
export function updateNagTime(now) {
    return myxhr.put('/state', { lastNagTime: now })
}

/*
    GET
    /state -
    Response: 200 success
    {
        "accountStatus" "noCookie | anonymousPerson | identifiedPerson",
        "firstName”: "firstName",
            "seenTrainingIntroVideo": boolean
    }
*/

export function get() {
    return myxhr.get('/state');
}
