import { useEffect, useState } from "react";
import api from "../api";

export const Survey = ({ onChange, onShowDescription }) => {
	const [values, setValues] = useState({});
	const [survey, setSurvey] = useState({});

	useEffect(() => {
		if (survey.questions) {
			document.querySelectorAll('select').forEach(s => {
				s.addEventListener("change", (e) => onItemChange(e.target.name, e.target.value));
			});
			document.querySelectorAll('select').forEach(s => s.customSelect || global.customSelect(s));
		}
	}, [survey]);

	useEffect(() => {
		const getSurvey = async () => {
			const survey = await api.survey.get();
			const responses = await api.responses.get();

			if (responses?.data?.responses) {
				responses.data.responses.forEach(r => onItemChange(r.item, r.valString));
			}
			setSurvey(JSON.parse(survey.data.fullSurveyJson));
		};
		getSurvey();
	}, []);

	const onItemChange = (item, value) => {
		setValues(prevState => ({
			...prevState,
			[item]: value
		}));
		onChange(item, value);
	}


	if (!survey.questions)
		return null;

	return <>
		{survey.questions.map((q, i) => <Question key={i} data={q} values={values} onShowDescription={onShowDescription} />)}
	</>;
};

const Question = ({ data: { item, question, options, context, description }, values, onShowDescription }) => {
	const isVisible = getVisibility(context, values);
	return (
		<div className="form-group" style={!isVisible ? { display: 'none' } : {}}>
			<select id={context} name={item} defaultValue={values[item]} placeholder={question}>
				<option value>{question}</option>
				{options.map(o => <option key={o}>{o}</option>)}
			</select>
		</div>
	)
};

const getVisibility = (context, values) => {
	if (!context) return true;

	if (context.indexOf(" == ") > -1) {
		const parts = context.split(" == ");
		return (values[parts[0]] || "") === removeQuotes(parts[1]);
	}

	if (context.indexOf(" != ") > -1) {
		const parts = context.split(" != ");
		return (values[parts[0]] || "") !== removeQuotes(parts[1]);
	}

	return false;
}

const removeQuotes = (str) => {
	return str.substring(1, str.length - 1);
}
