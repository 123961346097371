import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Desktop } from "./Desktop";
import { DrillsList } from "./DrillsList";
import { haveState, currentState } from '../globalState';

export const Completed = ({ next, todo }) => {
	const history = useHistory();
	const [completed, setCompleted] = useState(0);

	if (haveState && !currentState.showProgressPage)
		history.replace(next);

	const goNext = () => {
		history.push(next);
	}

	const drillsTotal = 4;

	useEffect(() => {
		if (todo) {
			setCompleted(drillsTotal - todo.length);
		}
	}, [todo]);

	return (
		<>
			<main className="main">
				<DrillsList todo={todo} />
				<Desktop />
			</main>

			<footer className="footer">
				<div className="wrapper">
					<div className="footer-btns">
						{next === '/d/home'
							? <button className="footer-btn btn" onClick={goNext}>See your Reflexion Score™</button>
							: <button className="footer-btn btn" onClick={goNext}>next ({completed}/{drillsTotal})</button>
						}
					</div>
				</div>
			</footer>
		</>
	);
}
