import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getState } from "../globalState";

export const RedirectOnNoCookie = ({ children }) => {
	const history = useHistory();
	const [accountStatus, setAccountStatus] = useState('');

	useEffect(() => {
		const fetchData = async () => {
			const data = await getState();
			setAccountStatus(data.accountStatus);
		};
		fetchData();
	}, []);

	if (!accountStatus)
		return null;

	if (accountStatus === "noCookie") {
		history.push('/');
		return null;
	}

	if (accountStatus === "passwordlessPerson") {
		history.push('/d/forgot-password');
		return null;
	}

	return children;
};
