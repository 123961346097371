import { Link } from "react-router-dom";
import { Desktop } from "../components/Desktop";

export const Intro = () => (
	<>
		<main className="main">
			<section className="about js-section section">
				<div className="wrapper">
					<h2 className="about-title">
						Test Your Neuro-Fitness To Unlock Neuro-Training
					</h2>
					<p className="about-txt">This will only take about 3 minutes.</p>
					<div className="about-items">
						<div className="about-item">
							<div className="about-img">
								<div className="about-img__icon">
									<i className="svg-image-about-icon-1" />
								</div>
							</div>
							<div className="about-info">
								<h3 className="about-item__title">Reaction Time</h3>
								<p className="about-item__descr">
									How fast your brain and body can react
								</p>
							</div>
						</div>
						<div className="about-item">
							<div className="about-img">
								<div className="about-img__icon">
									<i className="svg-image-about-icon-2" />
								</div>
							</div>
							<div className="about-info">
								<h3 className="about-item__title">Inhibition</h3>
								<p className="about-item__descr">
									Stopping yourself from reacting to the wrong things
								</p>
							</div>
						</div>
						<div className="about-item">
							<div className="about-img">
								<div className="about-img__icon">
									<i className="svg-image-about-icon-3" />
								</div>
							</div>
							<div className="about-info">
								<h3 className="about-item__title">Prioritization</h3>
								<p className="about-item__descr">
									Doing the most important things first
								</p>
							</div>
						</div>
						<div className="about-item">
							<div className="about-img">
								<div className="about-img__icon">
									<i className="svg-image-about-icon-4" />
								</div>
							</div>
							<div className="about-info">
								<h3 className="about-item__title">Mental Flexibility</h3>
								<p className="about-item__descr">
									Responding quickly to different situations
								</p>
							</div>
						</div>
						<div className="about-item">
							<div className="about-img">
								<div className="about-img__icon">
									<i className="svg-image-about-icon-5" />
								</div>
							</div>
							<div className="about-info">
								<h3 className="about-item__title">Processing Speed</h3>
								<p className="about-item__descr">
									How fast your brain processes information
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Desktop />
		</main>
		<footer className="footer">
			<div className="wrapper">
				<div className="footer-btns">
					<Link className="footer-btn btn" to="/a/drills">
						Let’s Begin!
					</Link>
				</div>
			</div>
		</footer>
	</>
)
