import api from './api';

//
// query parameters
//
// Index.jsx examines query parameters and sets things here, so they are available to other modules
//

// query parameter - omitMobileCheck
// use presence/absence of a window global variable because non-module main.js needs to reference this.

export function disableMobileCheck() {
	window.$rfxn_mobileCheckDisabled = true;
	global.vision();  // update the DOM if necessary
}

export function mobileCheckDisabled() {
	return ("$rfxn_mobileCheckDisabled" in window);
}

// query parameter - userSession

export var isB2BUserSession = false;


//
// cache the results of api.state.get()
//

// users may check haveState at any time.
// if it's true, they may access currentState at any time in the future.
export var haveState = false;
export var currentState = {};

// fetch immediately, whether or not we have a cached copy
export async function fetchState() {
	const s = await api.state.get();
	currentState = s.data;
	haveState = true;
	console.log(`fetchState - ${JSON.stringify(currentState)}`)
	return currentState;
}

// if we have a cached copy, return it, otherwise fetch
export async function getState() {
	if (!haveState)
		await fetchState();
	return currentState;
}

// if state.get has not yet been called, issue an asynchronous call and return immediately
export function checkGetStateCalled() {
	if (!haveState)
		getState();
}
