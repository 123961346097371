import { Link } from "react-router-dom";
import { Desktop } from "./Desktop";

export const CheckYourEmail = () => (
	<>
		<main className="main">
			<section className="js-section section check-mail">
				<div className="wrapper">
					<div className="completed-inner">
						<div className="completed-img">
							<img src="/images/email.svg" alt="" />
						</div>
						<h2 className="completed-subtitle">Check your mail</h2>
						<div className="form-sub-title">
							We have sent password recovery instructions to your email
						</div>
					</div>
				</div>
				<div className="check-mail-footer">
					Did not receive the email? Check your spam filter or try{" "}
					<Link className="js-terms" to="/d/forgot-password">
						another email address
					</Link>
				</div>
			</section>
			<Desktop />
		</main>
	</>
)
