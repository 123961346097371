import { Desktop } from "./Desktop";

export const DrillIntroVideo = ({ current, next, onVideoFinished }) => {
	return (
		<main className="main">
			<section className="drill-video js-section section">
				<div className="drill-video__wrap js-video">
					<video
						id="video-page"
						autoPlay
						muted
						playsInline
						onEnded={() => onVideoFinished()}
					>
						<source src={`/video/intro-${current}.mp4`} type="video/mp4" />
					</video>
				</div>
				<div className="drill-video__btns">
					<a href="/" onClick={e => {
						e.preventDefault();
						onVideoFinished();
					}}> &lt;&lt; Skip &gt;&gt;</a>
				</div>
			</section>
			<Desktop />
		</main>
	);
}
