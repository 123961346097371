import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../api';
import { AccountCreated } from '../components/AccountCreated';
import { Desktop } from '../components/Desktop';
import { Survey } from '../components/Survey';
import { checkGetStateCalled } from '../globalState';

export const AdditionalInfo = () => {
    const [values, setValues] = useState({});
    const [done, setDone] = useState(false);
    const [description, setDescription] = useState({});

    useEffect(() => {
        checkGetStateCalled();
    }, []);

    const onChange = (item, value) => {
        setValues(prevState => ({
            ...prevState,
            [item]: value
        }));
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        await api.responses.post(values);
        setDone(true);
    }

    const onShowDescription = (question, description) => {
        setDescription({ question, description });
        document.querySelector('.main').classList.add('active-popup');
        document.getElementById('main-pp').classList.add('pp-open');
    }

    const onClose = () => {
        document.querySelector('.main').classList.remove('active-popup');
        document.querySelector('.pp-open').classList.remove('pp-open');
        setDescription({});
    }

    if (done)
        return <AccountCreated />;

    return (
        <>
            <main className="main">
                <section className="form-section js-section section">
                    <div className="wrapper">
                        <form className="form">
                            <div className="form-inner">
                                <h2 className="form-title">Additional Information</h2>
                                <div className="form-descr">
                                    Later, you'll be able to see how you compare to others like you.
                                </div>
                                <Survey onChange={onChange} onShowDescription={onShowDescription} />

                            </div>
                            <div className="form-btns">
                                <div className="form-group">
                                    <div className="form-links form-links--center">
                                        <Link
                                            className="form-link form-link--center"
                                            to="/d/training"
                                        >
                                            Skip{" "}
                                        </Link>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button className="form-btn btn" onClick={onSubmit}>
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
                <Desktop />
            </main>
            <div className="popup">
                <div className="popup__wrap">
                    <div className="popup__close" onClick={onClose}><i className="svg-image-times"></i></div>
                    <div className="popup__inner">
                        <div className="popup__tab" id="main-pp">
                            <h2 className="form-title">{description.question}</h2>
                            <p>{description.description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
