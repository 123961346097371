import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import api from "../api";
import { AgeToLow } from "../components/AgeToLow";
import { Desktop } from "../components/Desktop";
import { getState, checkGetStateCalled } from '../globalState';

const s2 = (x) => ("0" + x).slice(-2);

const formatTime = (date) => {
	return `${date.getFullYear()}-${s2(date.getMonth() + 1)}-${s2(date.getDate())}T${s2(date.getHours())}:${s2(date.getMinutes())}:${s2(date.getSeconds())}`;
}

const formatDate = (date) => {
	return `${date.getFullYear()}-${s2(date.getMonth() + 1)}-${s2(date.getDate())}`;
}

export const CreateAccount = () => {
	const history = useHistory();

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [birthdate, setBirthdate] = useState("");
	const [email, setEmail] = useState("");
	const [error, setError] = useState("");
	const [isChecked, setIsChecked] = useState(false);
	const [ageToLow, setAgeToLow] = useState(false);

	const [popupVisible, setPopupVisible] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!isChecked) {
			setError("you must accept terms & conditions");
			return;
		}

		const request = {
			firstName,
			lastName,
			email,
			birthdate
		}

		api.signup.put(request)
			.then(r => r.data)
			.then(r => history.push("/d/additional-information"))
			.catch(err => {
				if (err.response) {
					if ((err.response.data || "").trim() === "age below 13") {
						setAgeToLow(true);
					} else {
						setError("Error: " + err.response.data);
					}
				} else {
					console.log(err)
				}
			});
	}

	useEffect(() => {
		checkGetStateCalled();
	}, []);

	useEffect(() => {
		try {
			global.datepicker('.js-datepicker', {
				onSelect: (instance, date) => {
					if (!date) return;

					setBirthdate(formatDate(date));
				}
			})
		} catch { }
	}, []);

	useEffect(() => {
		const updateNag = async () => {
			await api.state.updateNagTime(formatTime(new Date()));
			await getState();
		}
		updateNag();
	}, []);

	const onTermsClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setPopupVisible(true);
	};

	if (ageToLow) return <AgeToLow />;

	return (
		<>
			<main className={`main ${popupVisible ? 'active-popup' : ''}`}>
				<section className="form-section js-section section">
					<div className="wrapper">
						<form className="form" method="get">
							<div className="form-inner">
								<h2 className="form-title">
									Let's create a quick account to track your progress!
								</h2>
								<div className="form-descr">
									Then you'll be able to come back here to view your scores and run
									drills to improve your cognitive abilities
								</div>
								<div className="form-group">
									<input
										className="form-control"
										type="text"
										id="firstName"
										name="firstName"
										placeholder="first name"
										value={firstName}
										onChange={e => setFirstName(e.target.value)}
									/>
								</div>
								<div className="form-group">
									<input
										className="form-control"
										type="text"
										id="lastName"
										name="lastName"
										placeholder="last name"
										value={lastName}
										onChange={e => setLastName(e.target.value)}
									/>
								</div>
								<div className="form-group">
									<input
										className="form-control form-control--date js-datepicker"
										type="text"
										placeholder="select Date"
										id="birthDate"
										name="birthDate"
									/>
								</div>
								<div className="form-group">
									<input
										className="form-control"
										type="email"
										id="email"
										name="email"
										placeholder="email"
										value={email}
										onChange={e => setEmail(e.target.value)}
									/>
								</div>
								<div className="form-group">
									<label className="form-checkbox">
										<input type="checkbox" name="termsAndConditions"
											checked={isChecked}
											onChange={e => setIsChecked(e.target.checked)} />
										<span className="form-checkmark" />
										<span className="form-checktext form-checktext--alt">
											I agree to <a className="js-terms" href="/" onClick={onTermsClick}>Terms &amp; Conditions</a>
										</span>
									</label>
								</div>
								{error && <div className="form-descr" style={{ marginTop: '10px', color: '#DC0019' }}>
									{error}
								</div>}
							</div>
							<div className="form-btns">
								<div className="form-group">
									<div className="form-links form-links--center">
										<Link className="form-link form-link--center" to="/d/training">
											Skip{" "}
										</Link>
									</div>
								</div>
								<div className="form-group">
									<button className="btn form-btn" onClick={handleSubmit}>
										Next <span>(1/2)</span>
									</button>
								</div>
							</div>
						</form>
					</div>
				</section>
				<Desktop />
			</main>
			<div className="popup">
				<div className="popup__wrap">
					<div className="popup__close" onClick={() => setPopupVisible(false)}>
						<i className="svg-image-times" />
					</div>
					<div className="popup__inner">
						<h2 className="form-title">Terms &amp; Conditions</h2>
						<p>BY CLICKING THE "ACCEPT" BUTTON/CHECKING THE "ACCEPT" BOX, YOU (A) ACCEPT THIS ACKNOWLEDGEMENT, WAIVER AND RELEASE AGREEMENT (THIS "AGREEMENT") AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS; AND (B) REPRESENT ANDWARRANT THAT YOU ARE OF LEGAL AGE TO ENTER INTO A BINDING AGREEMENT. IF YOU DO NOT AGREE TO ALL OF THESE TERMS, DO NOT CHECK THE BOX OR CLICK THE BUTTON.</p>

						<p>I understand that by using the software and all information obtained or related thereto (collectively, the "Software") provided by Reflexion Interactive Technologies, Inc, a Delaware Corporation (the "Company"), I am agreeing to the terms of this Agreement. In consideration of my use of the Software, and for other good and valuable consideration, I hereby agree as follows:</p>

						<p>1. I understand that the Company is not a healthcare professional and does not provide medical, health or other professional services, treatment, diagnosis, consultation, information or advice. I understand that the Software has not been licensed, approved, endorsed or reviewed or reviewed by any government agency or licensing body and are not replacements for proper medical care, and are not intended to be medical devices, and I agree that I am solely responsible for obtaining proper treatment for my health conditions. I may provide the information and reports received from the Software to my healthcare providers at my own responsibility, understanding that the Software is provided without warranty, except as required by law. For the avoidance of doubt, I understand that no warranty or guarantee is made that anySoftware will successfully diagnose or prevent any injury or condition of any person. In no event shall my use of the Software create a physician-patient relationship, or any similar relationship by and between myself and the Company. As a result, I acknowledge that theCompany shall have no duty of confidentiality, duty to provide information, or any other fiduciary duty or obligation that customarily arises from the foregoing relationships. My use of the Software is purely voluntary and I elect to use the Software despite any of the foregoing.</p>

						<p>2. By using the Software or providing the Company with my personal information, I am accepting and consenting to the practices, terms and conditions described in the Privacy Policy. I understand that at all times my information will be treated in accordance with the Company's Privacy Policy, which is incorporated by reference into this Agreement. I acknowledge and agree that the information obtained from the Software is not a substitute for regular monitoring and medical care, and that I will ensure that all appropriate treatment, attention and efforts are made by me and for my benefit to maintain my health and wellness. I understand that the Company's collection, storage and transmission of User Data (as such term is defined in the Privacy Policy) and any other information that I provide to the Company through my use of the Software is governed by the Privacy Policy.</p>

						<p>3. I acknowledge that my use of the Software involves known and unanticipated risks. I understand that these risks cannot be eliminated, and I expressly accept and assume all of the risks inherent in my use of the Software.</p>

						<p>4. I hereby voluntarily release, forever discharge, and agree to defend, indemnify and hold harmless the Company and its respective affiliates, subsidiaries and parent companies, officers,directors, employees, business partners and agents, from and against any and all claims, damages (including, but not limited to, death or disability), obligations, losses, liabilities, costs ordebt, and expenses (including, but not limited to, attorney's fees) arising from this Agreement and my use of the Software.</p>

						<p>5. Dispute Resolution.</p>

						<p>a. SUBJECT TO SECTION 5.b.ii BELOW, I ACKNOWLEDGE AND AGREE THAT ANY CLAIM,DISPUTE OR CONTROVERSY BETWEEN ME AND THE COMPANY ARISING OUT OF OR RELATING TO (1) THIS AGREEMENT, INCLUDING THE VALIDITY OF THIS SECTION, AND (2) MY USE OF THE SOFTWARE (COLLECTIVELY, THE "DISPUTE") SHALL BE RESOLVED,AT COMPANY'S SOLE ELECTION, EITHER BY JUDICIAL RESOLUTION OR EXCLUSIVELY AND FINALLY BY BINDING ARBITRATION. I UNDERSTAND THAT WITHOUT THIS PROVISION I WOULD HAVE HAD A RIGHT TO LITIGATE A DISPUTE THROUGH A COURT BEFORE A JURY OR JUDGE, AND THAT I HAVE EXPRESSLY AND KNOWINGLY WAIVED THOSE RIGHTS AND AGREE INSTEAD TO RESOLVE ANY DISPUTES THROUGH BINDINGARBITRATION IN ACCORDANCE WITH THE PROVISIONS OF THIS SECTION.</p>

						<p>b. I ACKNOWLEDGE AND AGREE THAT:</p>

						<p>i. IF ARBITRATION IS THE MEANS OF RESOLUTION SELECTED BY COMPANY IN ITS SOLE DISCRETION, PRIOR TO SUBMITTING A CLAIM FOR ARBITRATION, EITHER PARTYSHALL FIRST NOTIFY THE OTHER PARTY TO TRY TO RESOLVE THE DISPUTE. IF THE DISPUTE IS NOT RESOLVED WITHIN 60 DAYS OF SUCH NOTIFICATION, THEN THE CLAIM WILL BE SUBMITTED FOR ARBITRATION. THE ARBITRATION OF ANY DISPUTE OR CLAIM SHALL BE CONDUCTED IN ACCORDANCE WITH THE THEN CURRENT AND APPLICABLE RULES OF THE AMERICAN ARBITRATION ASSOCIATION ("AAA") AS MODIFIED BY THIS AGREEMENT. THE ARBITRATION SHALL OCCUR BEFORE A SINGLE ARBITRATOR IN LANCASTER, PENNSYLVANIA, WHO MUST BE AN ATTORNEY WITH AT LEAST FIVE YEARS OF EXPERIENCE IN SOFTWARE ENGINEERING OR DEVELOPMENT. ANY DECISION OR AWARD BY THE ARBITRATOR RENDERED IN AN ARBITRATION PROCEEDING SHALL BE FINAL AND BINDING ON EACH PARTY, AND MAY BE ENTERED AS A JUDGMENT IN ANY COURT OF COMPETENT JURISDICTION. IF EITHER PARTY BRINGS A DISPUTE IN A COURT OR OTHER NON-ARBITRATION FORUM, THE ARBITRATOR OR JUDGE MAY AWARD THE OTHER PARTY ITS REASONABLE COSTS AND EXPENSES (INCLUDING BUT NOT LIMITED TO ATTORNEYS' FEES) INCURRED IN ENFORCING COMPLIANCE WITH THIS BINDING ARBITRATION PROVISION, INCLUDING STAYING OR DISMISSING SUCH DISPUTE. ANY ARBITRATION SHALL BE
							CONFIDENTIAL, AND NEITHER I, NOR COMPANY NOR THE ARBITRATOR MAY DISCLOSE THE EXISTENCE, CONTENT OR RESULTS OF ANY ARBITRATION, EXCEPT ASMAY BE REQUIRED BY LAW OR FOR PURPOSES OF ENFORCEMENT OR APPEAL OF THE ARBITRATION AWARD. JUDGMENT ON ANY ARBITRATION AWARD MAY BE ENTERED IN ANY COURT HAVING PROPER JURISDICTION. IF ANY PORTION OF THIS ARBITRATION CLAUSE IS DETERMINED BY A COURT TO BE INAPPLICABLE OR INVALID,THEN THE REMAINDER SHALL STILL BE GIVEN FULL FORCE AND EFFECT.</p>

						<p>ii. EXCEPT FOR INDIVIDUAL SMALL CLAIMS ACTIONS WHICH CAN BE BROUGHT IN ANY SMALL CLAIMS COURT WHERE JURISDICTION AND VENUE ARE PROPER, all matters arising out of or relating to this Agreement shall be governed by and construed in accordance with the internal laws of the State of Delaware without giving effect to any choice or conflict of law provision or rule. Any arbitration, legal suit, action, or proceeding arising out of or relating to this Agreement or the transactions contemplated hereby shall, in the event that Company, in its sole discretion, has chosen to have disputed matters resolved by judicial determination, be exclusively instituted in the federal courts of the United States of America or the courts of the Commonwealth of Pennsylvania in each case located in the City of Lancaster and County of Lancaster, and I irrevocably submit to the exclusive jurisdiction of such courts in any such legal suit, action, or proceeding. Service of process, summons, notice, or other document by mail to my address on file with the Company shall be effective service of process for any suit, action, orother proceeding brought in any such court.</p>

						<p>I have had sufficient time to read this entire Agreement and, should I choose to do so, consult with legal counsel prior to accepting. I fully understand the terms of this Agreement, and have agreed to such terms freely and voluntarily without any inducement, assurance, or guarantee being made to me.</p>
					</div>
				</div>
			</div>
		</>
	);

}
