import { useEffect } from "react";
import Lottie from 'react-lottie';

import BubbleRed from '../animations/Bubble_red.json';
import BubbleGreen from '../animations/Bubble_green.json';

export const BubbleTarget = ({ x, rule, onShow, onTouchStart }) => {

	useEffect(() => {
		const targetPosition = getOffset("target");
		if (targetPosition) {
			onShow(targetPosition);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const style = {
		position: "absolute",
		left: x === "10%" ? "10px" : "auto",
		right: x === "70%" ? "10px" : "auto",
		top: "0",
		bottom: "0",
		transform: "translateY(-50px)",
		margin: "auto",
		width: "120px",
		height: "120px"
	}

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: rule ? BubbleGreen : BubbleRed,
		renderer: 'svg'
	};

	return (
		<div
			id="target"
			onTouchStart={onTouchStart}
			style={style}>
			<Lottie options={defaultOptions} height={120} width={120} isPaused={false} isStopped={false} />
		</div>
	);
};


function getOffset(id) {
	const el = document.getElementById(id);
	if (!el)
		return null;

	const rect = el.getBoundingClientRect();
	return {
		left: rect.left + window.scrollX,
		top: rect.top + window.scrollY,
	};
}
