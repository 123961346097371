import { Link, useLocation } from "react-router-dom";
import QueryString from "qs";

const videos = {
	"line-drills": {
		title: "Line Drills",
		src: "https://www.youtube.com/embed/oeuWjRjRAd8",
		next: "lane-drills"
	},
	"lane-drills": {
		title: "Lane Drills",
		src: "https://www.youtube.com/embed/hFO34Urs0uI",
		next: "4-corners-drills"
	},
	"4-corners-drills": {
		title: "4-Corners Drills",
		src: "https://www.youtube.com/embed/w15VLStm_jw",
		next: "balldrop-drills"
	},
	"balldrop-drills": {
		title: "Ball Drop Drills",
		src: "https://www.youtube.com/embed/08tRF4UyBss",
		next: "wall-drills"
	},
	"wall-drills": {
		title: "Wall Drills",
		src: "https://www.youtube.com/embed/_e604zNXzto"
	}
};

export const Player = () => {
	const location = useLocation();

	const search = location.search.startsWith("?") ? location.search.substr(1) : location.search;

	const { video } = QueryString.parse(search);

	return (
		<>
			<main className="main">
				<div className="player">
					<div className="player-video">
						<iframe
							src={videos[video].src}
							title={videos[video].title}
							frameBorder={0}
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						/>
					</div>
				</div>
			</main>
			<footer className="footer footer--btns">
				<div className="wrapper">
					<div className="footer-btns">
						<Link className="footer-btn btn" to={'/d/training'}>
							Back to training
						</Link>
						{videos[video].next && <Link className="footer-btn btn" to={`/d/player?video=${videos[video].next}`}>
							Next
						</Link>}
					</div>
				</div>
			</footer>
		</>
	);
}