import { useEffect } from "react";
import { ShapeTarget } from "./ShapeTarget";

const Rule = ({ rule }) => (
	<div style={{
		top: "64px",
		textAlign: "center",
		fontSize: "3em",
		color: "white",
		fontWeight: 800,
		textTransform: "uppercase",
	}}>{rule}</div>
);

const Question = ({ question }) => (
	<ShapeTarget
		id="target"
		shape={question.shape}
		color={question.color}
		size={question.size}
		style={{
			top: '24px',
			left: 0,
			right: 0,
			margin: 'auto',
			transform: 'none',
			bottom: 'auto'
		}}
	/>
);

export const QuestionPrompt = ({ rule, question, onShow, promptType }) => {
	useEffect(() => {
		if (promptType === 'shape') {
			const position = getOffset('target');
			onShow(position, question);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [promptType, question]);

	return (
		<>
			{promptType === 'rule' && <Rule rule={rule} />}
			{promptType === 'shape' && question && <Question question={question} />}
		</>

	);
};

function getOffset(id) {
	const el = document.getElementById(id);
	if (!el)
		return null;

	const rect = el.getBoundingClientRect();
	return {
		left: rect.left + window.scrollX,
		top: rect.top + window.scrollY,
	};
}
