export default class Transcript {
	static RIGHT = 1;
	static LEFT = 2;
	static CENTER = 3;
	static NO_GO = -1;
	static DISMISSES_TARGET = 4;
	static DEACTIVATES_HOME_BASE = 5;
	static ACTIVATES_HOME_BASE = 6;

	constructor() {
		this.ts = {
			header: {},
			defaults: [],
			events: []
		};
	}

	saveHeader(key, val) {
		this.ts.header[key] = val;
	}

	saveDrillInfo(name, version) {
		this.saveHeader("drillName", name);
		this.saveHeader("drillVersion", version);
		this.saveHeader("deviceType", "mobileWeb");

		// TODO the following info must be gathered and saved:
		// agent - user agent string, see "window.navigator.userAgent" documentation
		// hpixels - x resolution
		// vpixels - y resolution
		// hdpi - horizontal DPI of display
		// vdpi - vertical DPI of display
		// transcript.saveHeader("userAgent", agent);
		// transcript.saveHeader("screenWidth", hpixels);
		// transcript.saveHeader("screenHeight", vpixels);
		// transcript.saveHeader("hDPI", hdpi);
		// transcript.saveHeader("vDPI", vdpi);
	}

	saveScore(score) {
		this.saveHeader("score", '' + score);
	}

	saveHits(hits) {
		this.saveHeader("hits", '' + hits);
	}

	saveMisses(misses) {
		this.saveHeader("misses", '' + misses);
	}

	/* saveHomeBase
	 * params:
	 * id - unique identifier for homeBase - transcript.LEFT or transcript.RIGHT
	 * x - x coord of home base center
	 * y - y coord of home base center
	 * default - name of homebase default
	 *         - e.g. homebase = new transcript.Square("myHomeBase")
	 *         - saveHomeBase(transcript.LEFT, x, y, homebase.name)
	 *         - saveHomeBase(transcript.RIGHT, x, y, homebase.name)
	 */
	saveHomeBase(id, x, y, def) {
		this.ts.events.push({
			type: "homeBase",
			id: id,
			x: x,
			y: y,
			default: def
		});
	}

	/* saveTarget
	 * params:
	 * id - unique identifier for target, typically incrementing int
	 * x - x coord of target center
	 * y - y coord of target center
	 * expected_touch - expected hand to touch target: transcript.LEFT, transcript.RIGHT, or transcript.NO_GO
	 * timestamp - the time the target appeared
	 * default - name of a default
	 *         - E.g. circle = new transcript.Circle("myCircle")
	 *         -      transcript.saveTarget(..., timestamp, circle.name)
	 */
	saveTarget(id, x, y, expected_touch, timestamp, def) {
		this.ts.events.push({
			type: "target",
			id: id,
			x: x,
			y: y,
			expectedTouch: expected_touch,
			default: def,
			timestamp: timestamp
		});
	}

	/* saveTouchStart
	 * params:
	 * type - transcript.DISMISSES_TARGET or transcript.DEACTIVATES_HOME_BASE
	 * id - unique identifier of the target or home base
	 * x - x coord of target center
	 * y - y coord of target center
	 * timestamp - time at which a touch is registered
	 */
	saveTouchStart(type, id, x, y, timestamp) {
		var event = {
			type: "touchStart",
			x: x,
			y: y,
			timestamp: timestamp
		};

		if (type === Transcript.ACTIVATES_HOME_BASE) {
			event.activates = id;
		} else if (type === Transcript.DEACTIVATES_HOME_BASE) {
			event.deactivates = id;
		} if (type === Transcript.DISMISSES_TARGET) {
			event.dismisses = id;
		}

		this.ts.events.push(event);
	}

	/* saveTouchMove
	 * params:
	 * type - transcript.DISMISSES_TARGET or transcript.DEACTIVATES_HOME_BASE
	 * id - unique identifier of the target or home base
	 * x - x coord of touch
	 * y - y coord of touch
	 * timestamp - time at which a touch is registered
	 */
	saveTouchMove(type, id, x, y, timestamp) {
		var event = {
			type: "touchMove",
			x: x,
			y: y,
			timestamp: timestamp
		};

		if (type === Transcript.DISMISSES_TARGET) {
			event.dismisses = id;
		} else if (type === Transcript.DEACTIVATES_HOME_BASE) {
			event.deactivates = id;
		}

		this.ts.events.push(event);
	}

	/* saveTouchEnd
	 * params:
	 * type - transcript.DISMISSES_TARGET or transcript.DEACTIVATES_HOME_BASE
	 * id - unique identifier of the target or home base
	 * x - x coord of touch
	 * y - y coord of touch
	 * timestamp - time at which a touch is registered
	 */
	saveTouchEnd(type, id, x, y, timestamp) {
		var event = {
			type: "touchEnd",
			x: x,
			y: y,
			timestamp: timestamp
		};

		if (type === Transcript.DISMISSES_TARGET) {
			event.dismisses = id;
		} else if (type === Transcript.DEACTIVATES_HOME_BASE) {
			event.deactivates = id;
		}

		this.ts.events.push(event);
	}

	/* stringify
	 * returns:
	 * string representation of transcript JSON
	 */
	stringify() {
		return JSON.stringify({ transcript: this.ts });
	}

	ts() {
		return this.ts;
	}

	reset() {
		this.ts = {
			header: {},
			defaults: [],
			events: []
		};
	}
}
