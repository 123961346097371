import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import api from '../api';
import { Header } from "../components/Header";
import { Desktop } from "../components/Desktop";
import { IntroVideo } from "../components/IntroVideo";
import { fetchState, getState, haveState, currentState, disableMobileCheck, isB2BUserSession } from '../globalState';
import { getNextStep } from "../assessmentSequence";
import { useLocation } from 'react-router-dom'

// Index
//
// this component displays the initial landing page and (optionally) the intro video.
//
// if we were invoked by an organization (B2B) customer with a token they got via POST /org/usersession,
// we omit the landing page; just show a blank screen until we have enough info to decide where to go next.

export const Index = () => {
	const [stateLoaded, setStateLoaded] = useState(haveState);
	const [b2bChecked, setB2bChecked] = useState(false);
	const [showVideo, setShowVideo] = useState(false);
	const [tokenError, setTokenError] = useState("");

	const history = useHistory();
	const location = useLocation();

	// in most cases, the initial progress screen (list of drills) is next
	var nextPage = "/a/drills";

	const queryParams = new URLSearchParams(location.search);

	const userSessionToken = queryParams.get('userSession');
	if (userSessionToken !== null)
		isB2BUserSession = true;

	if (queryParams.get('omitMobileCheck') === 'true')
		disableMobileCheck();

	useEffect(() => {
		console.log(`Index.jsx - init effect`);
		console.log(`    token = ${userSessionToken}`);

		// for the switch-to-mobile-device QR code screen
		if (document.querySelector('.js-desktop')) {
			document.querySelector('.js-desktop').setAttribute('style', 'height:' + window.innerHeight + 'px');
		}

		if (isB2BUserSession) {
			// we were invoked by an organization (B2B) customer with a token they got via POST /org/usersession.
			// we use that token to log in as the end user (creating a cookie session), then we start an assessment.
			// NOTE: the REST API call we make here implicitly creates an assessment, so we may NOT make one explicitly.
			// NOTE: assume B2B users don't want landing page or intro video, otherwise this page will get more complicated.
			// the first fetchState() is just to set the CSRF cookie.
			fetchState().then(() => {
				api.org.userSessionLogin({ userSessionToken: userSessionToken })
					.then(async () => {
						await fetchState();
						setStateLoaded(true);

						if (currentState.showVideos) {
							setShowVideo(true);
							setB2bChecked(true);
						} else
							history.push(nextPage);
					})
					.catch(err => {
						console.log(`setting tokenError to ${err.response.data}`);
						setTokenError(err.response.data);
					});
			});
		} else {
			// normal individual user
			setB2bChecked(true);
			const initAsync = async () => {
				await getState();
				setStateLoaded(true);

				if (currentState.accountStatus !== 'noCookie') {
					// the user is returning to an existing session via page reload or other manual navigation.
					// figure out where they were and go there immediately.
					const next = await getNextStep();
					console.log(`    Index.jsx - init effect - returning user - history.push(${next})`);
					history.push(next);
				}
			};

			initAsync();
		}
	}, []);

	useEffect(() => {
		console.log(`Index.jsx - stateLoaded effect`);
		global.heroImgHeight();
	}, [stateLoaded]);

	const onGetStartedClick = async (e) => {
		// we can only get here if we're a normal (non-B2B) user
		e.preventDefault();
		await api.newuser.post();
		await fetchState();

		if (currentState.showVideos) {
			setShowVideo(true);
		} else {
			await api.state.videoSeen();
			history.push(nextPage);
		}
	}

	const onVideoEnded = async () => {
		await api.state.videoSeen();
		history.push(nextPage);
	}

	console.log(`tokenError = ${tokenError}`);
	if (tokenError !== '') {
		return (
			<>
				<Header title="TOKEN LOGIN ERROR" />
				<main className="main">
					<section className="js-section section">
						<div className="wrapper center-content-main-axis">
							<div className="simple-text-msg">
								Token login failed - {tokenError}
							</div>
						</div>
					</section>
					<Desktop />
				</main>
			</>
		);
	}

	if (!stateLoaded || !b2bChecked) {
		// delay until we know what to do
		return (
			<>
			</>
		)
	}

	if (showVideo) {
		return (
			<main className="main">
				<IntroVideo
					canSkip={stateLoaded && currentState.seenTrainingIntroVideo}
					skipUrl={nextPage}
					onEnded={onVideoEnded} />
			</main>
		);
	}

	return (
		<>
			<main className="main">
				<div className="index">
					<section className="hero js-hero">
						<div className="hero-img">
							<img src="/images/hero-img.jpg" alt="" />
						</div>
					</section>
					<div className="hero-btns-block js-hero-btns">
						<div className="wrapper">
							<div className="hero-btns">
								<a className="hero-btn btn" href="/video" onClick={onGetStartedClick}>
									let's go
								</a>
							</div>
							<div className="hero-links">
								<p className="hero-links__txt">
									Already have an account?{" "}
									<Link className="hero-link" to="/d/login">
										Sign in
									</Link>{" "}
								</p>
							</div>
						</div>
					</div>
				</div>
				<Desktop />
			</main>
			<footer className="footer footer--wlogo">
				<div className="wrapper">
					<div className="footer-wlogo">
						<div className="header-logo" style={{ justifyContent: 'center' }}>
							<img src="/images/reflexion.png" alt="" />
						</div>
					</div>
				</div>
			</footer>
		</>
	);
}
