import * as myxhr from "./myxhr.js";
import ReactGA from "react-ga";

export function startNew() {
    return myxhr.post('/assessment');
}

export function drillCompleted(drillName, transcript) {
    ReactGA.pageview(`/z/assessment/${drillName}`);
    return myxhr.post(`/assessment/${drillName}`, transcript);
}
