import axios from "axios";

// this is a wrapper around axios, which forces the client to reload itself,
// if it receives a special response header or status code

// XXX - the argument to window.location.reload() is deprecated. how do we force a reload???

const msg = "Due to a server upgrade, this page must be reloaded.";

function ok(resp) {
	// alert("OK - " + resp.status + " - " + JSON.stringify(resp.data));  // XXX - debug
	if (resp.headers.hasOwnProperty("Ua-Client-Update")) {
		alert(msg);
		window.location.reload(true);
	}
	return resp;
}

function bad(err) {
	// alert("ERROR - " + JSON.stringify(err));  // XXX - debug
	if (err.response.status === 418) {
		alert(msg);
		window.location.reload(true);
	}
	throw err;
}

export function get(url, config) {
	return axios.get(url, config).then(ok, bad);
}

export function post(url, data) {
	// if (url === '/login') alert("attempting to log in " + data.email); // XXX - debug
	var config = {
		headers: {}
	};
	var tok = document.cookie.replace(/(?:(?:^|.*;\s*)ua_csrf\s*\=\s*([^;]*).*$)|^.*$/, "$1");
	if (tok !== "") {
		config.headers["Ua-Csrf"] = tok;
	}
	return axios.post(url, data, config).then(ok, bad);
}

export function put(url, data) {
	var config = {
		headers: {}
	};
	var tok = document.cookie.replace(/(?:(?:^|.*;\s*)ua_csrf\s*\=\s*([^;]*).*$)|^.*$/, "$1");
	if (tok !== "") {
		config.headers["Ua-Csrf"] = tok;
	}
	return axios.put(url, data, config).then(ok, bad);
}

export function del(url) {
	var config = {
		headers: {}
	};
	var tok = document.cookie.replace(/(?:(?:^|.*;\s*)ua_csrf\s*\=\s*([^;]*).*$)|^.*$/, "$1");
	if (tok !== "") {
		config.headers["Ua-Csrf"] = tok;
	}
	return axios.delete(url, config).then(ok, bad);
}
