import React, { Component } from "react";
import { questions, realQuestions } from "./questions";
import { drillCompleted } from "../assessmentSequence";
import { Completed } from "../components/Completed";
import RuleChangeController from "../controllers/RuleChangeController";
import { QuestionPrompt } from "../components/QuestionPrompt";
import { ShapeTarget } from "../components/ShapeTarget";
import { Button } from "../components/Icons";
import { withRouter } from "react-router";
import { DrillResult } from "../components/DrillResult";
import Transcript from "../controllers/Transcript";

const defaultState = {
	nextStep: null,
	baseHeld: false,
	promptType: 'none',
	result: null
};

class DrillRuleChange extends Component {
	constructor(props) {
		super(props);
		this.debug = process.env.NODE_ENV === 'development';
		this.state = defaultState;

		this.controller = new RuleChangeController(questions, realQuestions, d => this.setState(d));
	}

	componentDidMount() {
		document.getElementById('root').ontouchstart = function (e) { e.preventDefault(); };

		const homebasePosition = getOffset("homebase");
		this.controller.start(homebasePosition.left, homebasePosition.top);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
		this.controller.stop();
	}

	targetTouchStart = (e, correctIndex) => {
		e.stopPropagation();

		// ignore target touches if user is still touching home base
		if (this.state.baseHeld)
			return;

		// ignore target touches if the prompt isn't a shape
		if (this.state.promptType !== 'shape')
			return;

		this.controller.targetClicked(e.touches[0].clientX, e.touches[0].clientY, correctIndex);
		this.controller.nextQuestion();

		if (this.controller.isFinished()) {
			document.getElementById('root').ontouchstart = function () { };
			const isRetake = this.props.retake;

			drillCompleted("rule-change", isRetake, this.controller.transcript.stringify())
				.then(({ nextStep, result, todo }) => {
					this.setState({ nextStep, result, todo });
				});
		}
	};

	homeBaseHeld = (e) => {
		e.stopPropagation();

		this.controller.baseHeld(e.touches[0].clientX, e.touches[0].clientY);
		this.setState({ baseHeld: true });
	}

	homeBaseReleased = (e) => {
		e.stopPropagation();

		this.controller.baseReleased((e.touches[0] || {}).clientX, (e.touches[0] || {}).clientY)
		this.setState({ baseHeld: false });
	}

	onTouchMove = (e) => {
		const currentTarget = document.elementFromPoint(e.touches[0].clientX, e.touches[0].clientY);
		if ((currentTarget || {}).id !== "home-base") {
			this.homeBaseReleased(e);
		}
	}

	render() {
		if (this.state.result) {
			return <DrillResult drill="rule-change" result={this.state.result} />
		}

		if (this.state.nextStep) {
			return <Completed current="rule-change" next={this.state.nextStep} todo={this.state.todo} />;
		}

		const promptType = this.state.promptType;
		const { rule, question } = this.controller.getCurrent();

		return (
			<main className="main">
				<div className="game">
					<div className="game-field">
						{promptType !== 'none' && <QuestionPrompt
							rule={rule}
							questionIndex={this.controller.questionIndex}
							question={question}
							onShow={(position, question) => this.controller.targetShown(position.left, position.top, question)}
							promptType={promptType}
						/>}

						<ShapeTarget left="0px" color="blue" shape="triangle" size="small" onTouchStart={e => this.targetTouchStart(e, Transcript.LEFT)} style={{ transform: "translateY(-30px)" }} />
						<ShapeTarget right="0px" color="yellow" shape="circle" size="large" onTouchStart={e => this.targetTouchStart(e, Transcript.RIGHT)} style={{ transform: "translateY(-30px)" }} />

						<div
							id="homebase"
							style={{
								position: "absolute",
								right: 0,
								left: 0,
								bottom: '44px',
								width: '120px',
								height: '120px',
								margin: 'auto'
							}}
							onTouchStart={this.homeBaseHeld}
							onTouchEnd={this.homeBaseReleased}
							onTouchMove={this.onTouchMove}
						>
							<div
								style={{
									position: 'relative',
									height: '100px',
									width: '100px',
									left: '10px',
									top: '10px'
								}}
							>
								<Button active={this.state.baseHeld} />
							</div>
						</div>
					</div>
				</div>
			</main>
		);
	}
}

function getOffset(id) {
	const el = document.getElementById(id);
	if (!el)
		return null;

	const rect = el.getBoundingClientRect();
	return {
		left: rect.left + window.scrollX,
		top: rect.top + window.scrollY,
	};
}

export default withRouter(DrillRuleChange);
