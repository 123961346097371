export const DrillHint = () => (
	<div class="game-message js-game-message show" style={{ position: 'absolute', bottom: '120px' }}>
		<div class="game-message__txt">Please touch & hold both home bases
			<div class="game-message__point js-game-point"></div>
		</div>
		<svg xmlns="http://www.w3.org/2000/svg" width="300" height="50" viewBox="0 0 300 50">
			<defs>
				<marker id="arrowhead" markerWidth="10" markerHeight="7"
					refX="3" refY="3.5" orient="auto" fill="#fff" >
					<polygon points="0 0, 10 3.5, 0 7, 3 3.5" />
				</marker>
			</defs>
			<line x1="0" y1="0" x2="40" y2="0" stroke="#fff"
				stroke-width="1" marker-end="url(#arrowhead)" transform="translate(150,0) rotate(45)" />
			<line x1="0" y1="0" x2="40" y2="0" stroke="#fff"
				stroke-width="1" marker-end="url(#arrowhead)" transform="translate(150,0) rotate(135)" />
		</svg>
	</div>
);
