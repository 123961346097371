import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// prevent touch-and-hold from popping up a menu
window.addEventListener("contextmenu", function (e) { e.preventDefault(); })

// prevent pinch-zoom
window.addEventListener("touchmove", function (e) {
	if (e.scale !== 1) {
		e.preventDefault();
		e.stopImmediatePropagation();
	}
},
	{ passive: false }
);

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
