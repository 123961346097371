import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Desktop } from "../components/Desktop";
import { Header } from "../components/Header";
import { currentState, checkGetStateCalled } from '../globalState';

const VideoThumbnail = ({ img, title, video }) => (
	<div className="swiper-slide">
		<div className="training-link">
			<Link className="training-link__inner" to={video}>
				<span className="training-link__img">
					<img src={img} alt="" />
				</span>
				<span className="training-link__info">
					<span className="training-link__title">{title}</span>
				</span>
			</Link>
		</div>
	</div>
);

export const Training = () => {

	useEffect(() => {
		checkGetStateCalled();
	}, []);

	return (
		<>
			<Header title="Training" />
			<main className="main">
				<section className="training js-section section">
					<div className="wrapper">
						<Link className="training-back" to="/d/home">
							<i className="svg-image-back" /> Back to Reflexion Score
						</Link>
						<div className="training-descr">
							Recommended training to help you improve your cognitive skills.
						</div>
						<div className="training-section">
							<h2 className="training-title">TRAIN YOUR GAME</h2>
							<div className="swiper-container">
								<div className="swiper-wrapper">
									<VideoThumbnail key="line-drills" title="Line Drills" img="/images/thumbnails/UA_LINE_DRILL.jpg" video="/d/player?video=line-drills" />
									<VideoThumbnail key="gate-drills" title="Gate Drills" img="/images/thumbnails/UA_GATE_DRILLS.jpg" video="/d/player?video=gate-drills" />
									<VideoThumbnail key="star-drills" title="Star Drills" img="/images/thumbnails/UA_STAR_DRILLS.jpg" video="/d/player?video=star-drills" />
									<VideoThumbnail key="balldrop-drills" title="Ball Drop Drills" img="/images/thumbnails/UA_BALLDROP_DRILLS.jpg" video="/d/player?video=balldrop-drills" />
									<VideoThumbnail key="wall-drills" title="Wall Drills" img="/images/thumbnails/UA_WALL_DRILLS.jpg" video="/d/player?video=wall-drills" />
								</div>
							</div>
						</div>
						<div className="training-section">
							<h2 className="training-title">TRAIN YOUR MIND</h2>
							<div className="swiper-container">
								<div className="swiper-wrapper">
									<div className="swiper-slide">
										<div className="training-item">
											<Link className="training-item__link" to="/d/drills-minefield">
												<span className="training-item__img">
													<img src="/images/training-icon-1.svg" alt="" />
												</span>
												<span className="training-item__title">1. Minefield</span>
											</Link>
										</div>
									</div>
									<div className="swiper-slide">
										<div className="training-item">
											<Link className="training-item__link" to="/d/drills-reaction">
												<span className="training-item__img">
													<img src="/images/training-icon-2.svg" alt="" />
												</span>
												<span className="training-item__title">2. Reaction</span>
											</Link>
										</div>
									</div>
									<div className="swiper-slide">
										<div className="training-item">
											<Link className="training-item__link" to="/d/drills-rule-change">
												<span className="training-item__img">
													<img src="/images/training-icon-3.svg" alt="" />
												</span>
												<span className="training-item__title">3. Rule Change</span>
											</Link>
										</div>
									</div>
									<div className="swiper-slide">
										<div className="training-item">
											<Link className="training-item__link" to="/d/drills-go-no-go">
												<span className="training-item__img">
													<img src="/images/training-icon-4.svg" alt="" />
												</span>
												<span className="training-item__title">4. Go/No-Go</span>
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="training-section">
							<h2 className="training-title training-title--sm">
								LEARN MORE AT REFLEXION
							</h2>
							<div className="training-descr">
								Learn more about how you can train you neuro-skills using Reflexion.
							</div>
							<div className="training-article">
								<a className="training-article__link" href="https://reflexion.co" target="_blank" rel="noreferrer">
									<span className="training-article__img">
										<img src="/images/article-img-1.jpg" alt="" />
									</span>
									<span className="training-article__logo">
										<img src="/images/logo-1.png" alt="" />
									</span>
									<span className="training-article__txt">
										Learn more about neuro-training{" "}
										<i className="svg-image-arrow-top" />{" "}
									</span>
								</a>
							</div>
						</div>
					</div>
				</section>
				<Desktop />
			</main>
		</>
	);
}
