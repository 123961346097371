import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Desktop } from "./Desktop";

export const PasswordChanged = () => {
	const [buttonVisible, setButtonVisible] = useState(false);

	useEffect(() => {
		global.screenHeight();
		setButtonVisible(global.isTouchDevice());
	}, []);

	return (
		<>
			<main className="main">
				<section className="completed js-section section changed">
					<div className="wrapper">
						<div className="completed-inner">
							<div className="completed-img">
								<img src="/images/done.svg" alt="" />
							</div>
							<h2 className="completed-subtitle">
								Your password has been <span>Changed</span>.
								Please sign in on your mobile device.
							</h2>
						</div>
					</div>
				</section>
				<Desktop />
			</main>
			<footer className="footer">
				<div className="wrapper">
					<div className="footer-btns">
						{buttonVisible && <Link className="footer-btn btn" to="/d/login">
							Sign in
						</Link>}
					</div>
				</div>
			</footer>
		</>
	);
}