import { Link } from "react-router-dom";
import { Desktop } from "../components/Desktop";

export const SocialMedia = () => {
	return (
		<>
			<main className="main" style={{ backgroundColor: '#fff' }}>
				<section className="js-section section">
					<div className="wrapper image-by-width" style={{ padding: 0 }}>
						<img src={process.env.REACT_APP_API_BASE_URL + '/assessmentlatest'} alt="" />
						<h2 className="save-image-title">
							Save image to share to social
						</h2>
					</div>
				</section>
				<Desktop />
			</main>
			<footer className="footer" style={{ background: '#fff' }}>
				<div className="wrapper">
					<div className="footer-btns">
						<Link className="footer-btn btn" to="/d/home">
							Back
						</Link>
					</div>
				</div>
			</footer>
		</>
	)

}
