import React, { Component } from "react";
import { withRouter } from "react-router";
import { DrillResult } from "../components/DrillResult";

class ResultMinefield extends Component {
	constructor() {
		super();
		this.state = { foo: 0 };
	}

	render() {
		return <DrillResult drill="minefield" result={window.rfxnIndividualResult} />
	}
}

export default withRouter(ResultMinefield);
