import * as myxhr from "./myxhr.js";

/*
    /signup/ - gather user
    Sample request body:
    {
        “firstName”: “firstName”,
        “lastName”: “lastName”,
        “email”: “email@domain.com”,
        “password”: “password”
    }
    Response: 200 Success
    // users don't see the signup page until they've interacted substantially with the site.
    // an anonymous user record was created when the new user first arrived;
    // this fills it in and generates a verification email
*/

export function put(body) {
    return myxhr.put('/signup', body);
}