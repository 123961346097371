import axios from 'axios';
import * as assessmentstate from './assessmentstate';
import * as state from './state';
import * as assessmentlatest from './assessmentlatest';
import * as newuser from './newuser';
import * as assessment from './assessment';
import * as drill from './drill';
import * as responses from './responses';
import * as signup from './signup';
import * as account from './account';
import * as login from './login';
import * as pwforgot from './pwforgot';
import * as logout from './logout';
import * as dorecover from './dorecover';
import * as survey from './survey';
import * as org from './org';

const scenarioId = 1;
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL || `http://localhost:3001/${scenarioId}`;
axios.defaults.withCredentials = true;

// NOTE: deployment of changes to the client version string must be coordinated with
// server deployments. see the deploy/README.txt in the server repo for details.
axios.defaults.headers = { 'Ua-Client-Version': 'a0004' };

if (process.env.NODE_ENV === 'development') {
    console.log('API @', axios.defaults.baseURL);
}

const api = {
    assessment,
    assessmentstate,
    assessmentlatest,
    state,
    newuser,
    drill,
    org,
    responses,
    signup,
    account,
    login,
    pwforgot,
    logout,
    dorecover,
    survey
};

export default api;
