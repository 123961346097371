import { useEffect, useState } from "react";
import api from "../api";
import { CheckYourEmail } from "../components/CheckYourEmail";
import { Desktop } from "../components/Desktop";
import { checkGetStateCalled } from '../globalState';

export const ForgotPassword = () => {
	const [email, setEmail] = useState("");
	const [done, setDone] = useState(false);
	const [error, setError] = useState("");

	useEffect(() => {
		checkGetStateCalled();
	}, []);

	const handleSubmit = async () => {
		setError("");
		setDone(true);

		api.pwforgot.post({ email })
			.then(() => setDone(true))
			.catch(err => setError(err.response.data));
	};

	if (done)
		return <CheckYourEmail />;

	return (
		<>
			<main className="main">
				<section className="form-section js-section section">
					<div className="wrapper">
						<form className="form">
							<div className="form-inner">
								<h2 className="form-title form-title--mb">Reset password</h2>
								<div className="form-sub-title">
									Enter your email address and we’ll send an email with instructions
									to reset your password.
								</div>
								<div class="form-wrap">
									<div className="form-group">
										<input
											className="form-control"
											type="email"
											id="email"
											placeholder="email"
											value={email}
											onChange={e => setEmail(e.target.value)}
										/>
									</div>
									{error && <div className="form-descr" style={{ marginTop: '10px', color: '#DC0019' }}>
										Error: {error}
									</div>}
									<div class="form-group form-group--btn">
										<button className="form-btn btn" onClick={() => handleSubmit()}>
											Send instructions
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</section>
				<Desktop />
			</main>
		</>
	);
}
