import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import api from "../api";
import { fetchState, getState } from "../globalState";

const MyProfileItem = ({ setParentShown }) => {
	return (
		<li className="nav-item">
			<Link className="nav-link" to="/d/profile" onClick={() => setParentShown(false)}>
				My Profile{" "}
				<span className="nav-icon">
					<span className="nav-icon--default">
						<i className="svg-image-nav-arrow" />
					</span>{" "}
					<span className="nav-icon--hover">
						<i className="svg-image-nav-arrow-red" />
					</span>
				</span>
			</Link>
		</li>
	);
}

const SignupItem = ({ setParentShown }) => {
	return (
		<li className="nav-item">
			<Link className="nav-link" to="/d/create-account" onClick={() => setParentShown(false)}>
				Sign Up{" "}
				<span className="nav-icon">
					<span className="nav-icon--default">
						<i className="svg-image-nav-arrow" />
					</span>{" "}
					<span className="nav-icon--hover">
						<i className="svg-image-nav-arrow-red" />
					</span>
				</span>
			</Link>
		</li>
	);
}

export const Header = ({ title }) => {
	const history = useHistory();

	const [shown, setShown] = useState(false);
	const [signedUp, setSignedUp] = useState(false);

	useEffect(() => {
		global.navbarHeight();
	}, []);

	useEffect(() => {
		const dostate = async () => {
			const data = await getState();
			if (data.accountStatus === "identifiedPerson" || data.accountStatus === "passwordlessPerson") {
				setSignedUp(true);
			}
		};
		dostate();
	}, []);

	const openCookies = (e) => {
		e.preventDefault();
		global.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
	}

	const onLogOut = async () => {
		await api.logout.post();
		await fetchState();
		history.push('/');
	}

	return (
		<header className="header header--nav" style={{ zIndex: 100000 }}>
			<div className="wrapper">
				<div className="header-nav">
					<div className="header-title">{title}</div>
					<div className={`header-burger js-burger ${shown ? 'active' : ''}`} onClick={() => setShown(prevState => !prevState)}>
						<div className="header-burger-inner">
							<div className="header-burger-line"> </div>
							<div className="header-burger-line" />
						</div>
					</div>
					<div className={`header-navbar js-navbar ${shown ? 'show' : ''}`}>
						<div className="header-navbar__inner">
							<ul className="header-list">
								<li className="nav-item">
									<Link className="nav-link" to="/d/home" onClick={() => setShown(false)}>
										Home{" "}
										<span className="nav-icon">
											<span className="nav-icon--default">
												<i className="svg-image-nav-arrow" />
											</span>{" "}
											<span className="nav-icon--hover">
												<i className="svg-image-nav-arrow-red" />
											</span>
										</span>
									</Link>
								</li>
								<li className="nav-item">
									<Link className="nav-link" to="/a/drills" onClick={() => setShown(false)}>
										take Full Assessment{" "}
										<span className="nav-icon">
											<span className="nav-icon--default">
												<i className="svg-image-nav-arrow" />
											</span>{" "}
											<span className="nav-icon--hover">
												<i className="svg-image-nav-arrow-red" />
											</span>
										</span>
									</Link>
								</li>
								<li className="nav-item">
									<Link className="nav-link" to="/d/training" onClick={() => setShown(false)}>
										Training{" "}
										<span className="nav-icon">
											<span className="nav-icon--default">
												<i className="svg-image-nav-arrow" />
											</span>{" "}
											<span className="nav-icon--hover">
												<i className="svg-image-nav-arrow-red" />
											</span>
										</span>
									</Link>
								</li>
								{signedUp && <MyProfileItem setParentShown={setShown} />}
								{!signedUp && <SignupItem setParentShown={setShown} />}
								<li className="nav-item">
									<Link className="nav-link" onClick={onLogOut} >
										Log out{" "}
										<span className="nav-icon">
											<span className="nav-icon--default">
												<i className="svg-image-nav-arrow" />
											</span>{" "}
											<span className="nav-icon--hover">
												<i className="svg-image-nav-arrow-red" />
											</span>
										</span>
									</Link>
								</li>
							</ul>
							<div className="header-bottom">
								<div className="header-logo">
									<img src="/images/reflexion.png" alt="" />
								</div>
								<div className="header-bottom__policy">
									{" "}
									<a href="https://www.reflexion.co/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>
									<br />
									<a href="/" onClick={openCookies}>Cookie Preferences</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
}
