import React from "react";

import ButtonLogo from './bubbles-svg/blue-round.svg';
import ActiveButtonLogo from './bubbles-svg/blue-round-active.svg';

const ButtonIcon = ({ active }) => {
	const buttonType = false ? ActiveButtonLogo : ButtonLogo

	return (
		<div
			id="home-base"
			className={`game-item ${active ? 'touched' : ''}`}
			style={{ height: 100, width: 100 }}
		><img src={buttonType} alt="button" width="80" height="80" style={{ margin: '10px' }} /></div>
	)
}

export const Button = React.memo(ButtonIcon);
