export const DrillsList = ({ todo = null }) => {
	return (
		<section className="drills js-section section">
			<div className="wrapper">
				<h2 className="drills__title">
					COMPLETE THE DRILLS AND SEE WHERE YOU STACK UP
				</h2>
				<div className="drills__items">
					<div className={`drills__item ${todo !== null && todo.indexOf('minefield') === -1 && 'drills__item--completed'}`}>
						<div className="drills__info">
							<h3>Minefield</h3>
						</div>
						<div className="drills__img">
							<img src="/images/training-icon-1.svg" alt="Minefield" />
						</div>
					</div>
					<div className={`drills__item ${todo !== null && todo.indexOf('go-no-go') === -1 && 'drills__item--completed'}`}>
						<div className="drills__info">
							<h3>Go/No-Go</h3>
						</div>
						<div className="drills__img">
							<img src="/images/training-icon-4.svg" alt="Go/No-Go" />
						</div>
					</div>
					<div className={`drills__item ${todo !== null && todo.indexOf('reaction') === -1 && 'drills__item--completed'}`}>
						<div className="drills__info">
							<h3>Reaction</h3>
						</div>
						<div className="drills__img">
							<img src="/images/training-icon-2.svg" alt="Reaction" />
						</div>
					</div>
					<div className={`drills__item ${todo !== null && todo.indexOf('rule-change') === -1 && 'drills__item--completed'}`}>
						<div className="drills__info">
							<h3>Rule Change</h3>
						</div>
						<div className="drills__img">
							<img src="/images/training-icon-3.svg" alt="Rule Change" />
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
