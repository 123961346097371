import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from '../api';
import { Desktop } from "../components/Desktop";
import { DrillsList } from "../components/DrillsList";
import { getNextStep } from "../assessmentSequence";
import { getState, haveState, currentState, isB2BUserSession } from '../globalState';

export const Drills = () => {
    const [stateLoaded, setStateLoaded] = useState(haveState);
    const [redirectChecked, setRedirectChecked] = useState(false);

    const history = useHistory();

    useEffect(() => {
        const initAsync = async () => {
            await getState();
            setStateLoaded(true);

            if (!currentState.showProgressPage) {
                if (!isB2BUserSession)
                    await api.assessment.startNew();
                const next = await getNextStep();
                console.log(`Drills.jsx - skipping display of progress page - history.replace(${next})`);
                history.replace(next);
            }
            setRedirectChecked(true);
        };

        initAsync();
    }, []);

    const onLetsBegin = async () => {
        if (!isB2BUserSession)
            await api.assessment.startNew();
        const next = await getNextStep();
        console.log(`Drills.jsx/onLetsBegin - history.push(${next})`);
        history.push(next)
    };

    if (!stateLoaded || !redirectChecked) {
        // delay until we know what to do
        return (
            <>
            </>
        )
    }

    return (
        <>
            <main className="main">
                <DrillsList />
                <Desktop />
            </main>
            <footer className="footer">
                <div className="wrapper">
                    <div className="footer-btns">
                        <button className="footer-btn btn" onClick={onLetsBegin}>
                            Next
                        </button>
                    </div>
                </div>
            </footer>
        </>
    );
}
