import { useLocation } from "react-router-dom";
import QueryString from "qs";
import { Completed } from "../components/Completed";

export const DrillCompleted = () => {
	const location = useLocation();

	const search = location.search.startsWith("?") ? location.search.substr(1) : location.search;
	const { current, next } = QueryString.parse(search);

	return <Completed current={current} next={next} />;
};