import lottie from 'lottie-web';

import ExplosionAnim from '../animations/minefield/explosion-anim.json';
import MissedTouchAnim from '../animations/minefield/missed-touch-anim.json';
import TargetAnim from '../animations/minefield/target-anim.json';
import Transcript from "./Transcript";

// Copyright (C) 2021 Reflexion Interactive Tehnologies, Inc., All Rights Reserved.

// the div named "screen" is a container the full size of the viewport.
//
// targets are child divs of the screen div, with a time-varying background color and an ontouchstart handler
//
// the screen div and its target children are the only data structure in this program.
// we create and destroy target divs as appropriate, and we add some of our own attributes to them.
//
// all times are in milliseconds.

export function startMinefield(minefieldCompleted) {
	console.log("Starting minefield.")

	document.body.style.backgroundColor = "black";

	var screen = document.getElementById("screen");

	var mainLoopPeriod = 20;

	var targetSizeFrac = 0.2;        // target size, as fraction of the smallest screen dimension
	var innerSizePct = 75;           // size of smaller square inside target, as percent of target size

	var marginPx = 4;                // targets must be separated by at least this much space
	var maxTargets = 13;             // if we allow too many targets, we won't be able to find a place to put them
	var targetRetries = 500;         // number of times to retry after trying to place a target and finding it overlapping

	// TODO: This value is never updated. Should we remove it?
	var targetStyle = "animated";

	var numTargets = 0;
	var lastDismissedX = 0;            // remember pos of last dismissed target, so we don't put one there
	var lastDismissedY = 0;

	// difficulty parameters - these are changed over time, to increase difficulty
	var targetsPerMinute = 250;
	var targetLifespan = 4000;

	// difficulty increase over time
	var difficultyChangePeriod = 1000;    // increase difficulty once every second
	var targetsPerMinuteDelta = 10;       // each period, increase targetsPerMinute
	var targetLifespanDelta = 33;         // each period, decrease targetLifeSpan

	// colors
	var startR = 0;
	var startG = 255;
	var startB = 0;

	var midR = 224;
	var midG = 224;
	var midB = 0;

	var endR = 255;
	var endG = 0;
	var endB = 0

	var startColor = colorString(startR, startG, startB);
	var backgroundColor = colorString(0, 0, 0);

	var scrWidth = screen.clientWidth;
	var scrHeight = screen.clientHeight;

	var targetSize = (scrWidth < scrHeight) ? scrWidth * targetSizeFrac : scrHeight * targetSizeFrac;
	var innerSize = innerSizePct * targetSize / 100;  // for targetStyle = "innerdiv"
	var innerPad = (targetSize - innerSize) / 2;      // offset of inner square relative to target square

	var explosionSize = 2 * targetSize;
	var missSize = 3 * targetSize;

	var score = 0;
	var misses = 0;
	var gameOver = 0;
	var scoreDelay = 300;                          // delay before score appears

	// return the color corresponding to the given age of a target in milliseconds.
	// interpolate in 2 stages - from start to mid, then from mid to end.
	function interpolateColor(age) {
		var ageFrac = age / targetLifespan;
		var startFrac = 0.0;
		var endFrac = 1.0;

		if (ageFrac < 0.5) {
			endFrac = ageFrac / 0.5;
			startFrac = 1.0 - endFrac;
			return colorString(startR * startFrac + midR * endFrac, startG * startFrac + midG * endFrac, startB * startFrac + midB * endFrac);
		} else {
			endFrac = (ageFrac - 0.5) / 0.5;
			startFrac = 1.0 - endFrac;
			return colorString(midR * startFrac + endR * endFrac, midG * startFrac + endG * endFrac, midB * startFrac + endB * endFrac);
		}
	}

	// make a div whose top left corner is (x, y) to hold a visible screen element like a target or an explosion
	function makeElt(x, y, height, width) {
		var elt = document.createElement("div");
		elt.style.position = "fixed";
		elt.style.height = height + "px";
		elt.style.width = width + "px";
		elt.style.left = x + "px";
		elt.style.top = y + "px";
		return elt;
	}

	// return true if the targets overlap, otherwise return false
	function targetsOverlap(x1, y1, x2, y2) {
		let delta = targetSize + marginPx;
		if ((x1 >= x2 + delta) || (x2 >= x1 + delta) || (y1 >= y2 + delta) || (y2 >= y1 + delta))
			return false;
		return true;
	}

	// create a new target whose top left corner is (x, y)
	function createTarget(x, y) {
		let elt = makeElt(x, y, targetSize, targetSize);
		elt.className = "minefield-target";

		switch (targetStyle) {
			case "animated":
				elt.style.backgroundColor = "transparent";
				elt.rfxnAnim = lottie.loadAnimation({
					container: elt,
					renderer: 'svg',
					loop: false,
					autoplay: false,
					animationData: TargetAnim
				});
				elt.rfxnFrames = elt.rfxnAnim.getDuration(true);
				elt.rfxnAnim.goToAndStop(1, true);
				break;
			case "fulldiv":
				elt.style.backgroundColor = backgroundColor;
				break;
			case "innerdiv":
				// only a smaller, inner div is visible, but the outer div still gets touch events
				elt.style.backgroundColor = "transparent";
				var inner = document.createElement("div");
				inner.style.position = "relative";
				inner.style.height = innerSize + "px";
				inner.style.width = innerSize + "px";
				inner.style.left = innerPad + "px";
				inner.style.top = innerPad + "px";
				inner.style.color = startColor;
				elt.appendChild(inner);
				break;
			default:
				elt.style.backgroundColor = colorString(255, 255, 255);
				elt.innerText = "bad target style";
		}

		// stick our own stuff onto the element
		var d = new Date();
		elt.rfxnCreatedTime = d.getTime();
		elt.rfxnX = x;
		elt.rfxnY = y;

		const targetTouch = function (ev) {
			// console.log("TARGET TOUCH - X: " + elt.rfxnX + " - Y: " + elt.rfxnY)
			ev.preventDefault();
			ev.stopPropagation();
			if (!gameOver) {
				lastDismissedX = this.rfxnX;
				lastDismissedY = this.rfxnY;
				this.style.visibility = 'hidden';
				if (targetStyle === "animated")
					elt.rfxnAnim.destroy();
				screen.removeChild(this);
				numTargets--;
				score++;
			}
		}
		if ('ontouchstart' in window) {
			elt.ontouchstart = targetTouch;
		} else {
			// XXX - this is functional but is unusably slow!
			elt.onclick = targetTouch;
		}

		screen.appendChild(elt);
	}

	// try to place a new target. return true if success.
	function placeTarget() {
		var targets = Array.from(screen.children);
		var placed = 0;

		for (let spin = 0; spin < targetRetries; spin++) {
			var haveOverlap = 0;

			var x = Math.random() * (scrWidth - targetSize);
			var y = Math.random() * (scrHeight - targetSize);

			if (targetsOverlap(x, y, lastDismissedX, lastDismissedY))
				continue;

			for (let i = 0; i < targets.length; i++) {
				if (targets[i].className !== "minefield-target")  // skip any child of the screen div that's not a target
					continue;

				if (targetsOverlap(x, y, targets[i].rfxnX, targets[i].rfxnY)) {
					haveOverlap = 1;
					break;
				}
			}

			if (!haveOverlap) {
				placed = 1;
				createTarget(x, y);
				break;
			}
		}

		return placed;
	}

	// miss - screen got a touch event which was not caught by a target
	const missTouch = function (ev) {
		ev.preventDefault();
		ev.stopPropagation();
		if (gameOver)
			return;
		score--;
		misses++;

		// console.log("MISSED TOUCH - X: " + ev.touches[0].clientX + " - Y: " + ev.touches[0].clientY)

		// draw the missed-touch animation
		let elt = makeElt(ev.touches[0].clientX - (missSize / 2), ev.touches[0].clientY - (missSize / 2), missSize, missSize);
		elt.className = "missed-touch";
		elt.style.backgroundColor = "transparent";
		elt.style.zIndex = -1;  // put missed-touch animations BEHIND all targets so they don't block good touches on targets
		elt.rfxnAnim = lottie.loadAnimation({
			container: elt,
			renderer: 'svg',
			loop: false,
			autoplay: true,
			animationData: MissedTouchAnim
		});
		elt.rfxnAnim.onComplete = function () {
			elt.rfxnAnim.destroy();
			screen.removeChild(elt);
		};
		screen.appendChild(elt);
	};

	if ('ontouchstart' in window) {
		console.log("minefield - using ontouchstart");
		screen.ontouchstart = missTouch;
	} else {
		console.log("minefield - using onclick - ENABLE TOUCH EVENTS FOR BETTER PERFORMANCE");
		// XXX - this is functional but is unusably slow!
		screen.onclick = missTouch;
	}

	var startDate = new Date();
	var startTime = startDate.getTime();
	var lastDiffIncrease = startTime;

	var iTimer = setInterval(mainLoop, mainLoopPeriod);

	function mainLoop() {
		var d = new Date();
		var now = d.getTime()

		var targets = Array.from(screen.children);

		for (let i = 0; i < targets.length; i++) {
			if (targets[i].className !== "minefield-target")  // skip any child of the screen div that's not a target
				continue;

			let age = now - targets[i].rfxnCreatedTime;

			if (age > targetLifespan) {
				// GAME OVER
				gameOver = 1;
				clearInterval(iTimer);
				let deathElt = targets[i];

				// blank the target, draw explosion over target, then display score in target div
				deathElt.rfxnAnim.destroy();
				deathElt.style.visibility = "hidden";

				let expX = deathElt.rfxnX + (targetSize / 2) - (explosionSize / 2);
				let expY = deathElt.rfxnY + (targetSize / 2) - (explosionSize / 2);
				let exp = makeElt(expX, expY, explosionSize, explosionSize);
				exp.rfxnAnim = lottie.loadAnimation({
					container: exp,
					renderer: 'svg',
					loop: false,
					autoplay: false,
					animationData: ExplosionAnim
				});
				exp.rfxnAnim.setSpeed(0.5);
				exp.rfxnAnim.onComplete = function () {
					saveResult();
					exp.rfxnAnim.destroy();
					screen.removeChild(exp);
				};

				screen.appendChild(exp);
				exp.rfxnAnim.play();
				break;
			}

			let ageFrac = age / targetLifespan;

			switch (targetStyle) {
				case "animated":
					let frame = Math.floor(ageFrac * (targets[i].rfxnFrames - 1));
					targets[i].rfxnAnim.goToAndStop(frame, true);
					break;
				case "fulldiv":
					targets[i].style.backgroundColor = interpolateColor(age);
					break;
				case "innerdiv":
					targets[i].firstElementChild.style.backgroundColor = interpolateColor(age);
					break;
				default:
					break;
			}
		}

		if (numTargets < maxTargets && Math.random() < ((targetsPerMinute / 60) / (1000 / mainLoopPeriod))) {
			if (placeTarget())
				numTargets++;
		}

		if (now - lastDiffIncrease > difficultyChangePeriod) {
			lastDiffIncrease = now;
			targetsPerMinute += targetsPerMinuteDelta;
			targetLifespan -= targetLifespanDelta;
		}
	}

	function saveResult() {
		setTimeout(function () {
			const transcript = new Transcript();
			transcript.saveDrillInfo("minefield", "0.2.0");
			transcript.saveScore(score);
			transcript.saveMisses(misses);
			minefieldCompleted(transcript);
		}, scoreDelay);
	}
}

// make a string suitable for assigning to elt.style.backgroundColor from floating-point RGB values
function colorString(r, g, b) {
	return "rgb(" + Math.floor(r) + "," + Math.floor(g) + "," + Math.floor(b) + ")";
}