import { useEffect } from "react";
import { Link } from "react-router-dom";
import { checkGetStateCalled } from '../globalState';

export const EmailChanged = () => {

	useEffect(() => {
		checkGetStateCalled();
	}, []);

	return (
		<>
			<main className="main show-on-desktop">
				<section className="completed js-section section changed">
					<div className="wrapper">
						<div className="completed-inner">
							<div className="completed-img">
								<img src="/images/done.svg" alt="" />
							</div>
							<h2 className="completed-subtitle">
								Your email has been <br /> <span>Changed</span>
							</h2>
						</div>
					</div>
				</section>
			</main>
			<footer className="footer">
				<div className="wrapper">
					<div className="footer-btns">
						<Link className="footer-btn btn" to="/d/home">
							Back to Home Page
						</Link>
					</div>
				</div>
			</footer>
		</>
	);
}
