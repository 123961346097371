import { Timer } from "timer-node";
import Transcript from './Transcript';

const targetMinDelay = 350;
const targetMaxDelay = 1700;
const redTargetMaxLifetime = 1200;
const hintTimeoutMs = 2000;

function randomTargetDelay() {
	return targetMinDelay + Math.random() * (targetMaxDelay - targetMinDelay);
}

export default class GoNoGoController {
	questionIndex = 0;
	isLeftBaseHeld = false;
	isRightBaseHeld = false;

	constructor(steps, setState = () => { }) {
		this.steps = steps;
		this.setState = setState;

		this.baseTimer = new Timer({ label: "base-timer" });
		this.questionTimer = new Timer({ label: "question-timer" });
		this.hintTimer = new Timer();
		this.transcript = new Transcript();
		this.targetDelay = randomTargetDelay();
	}

	nextQuestion() {
		this.resetBaseTimer();
		this.questionIndex++;
		this.targetDelay = randomTargetDelay();
		this.setState({ questionIndex: this.questionIndex, showTarget: false });
	}

	start(leftHomebasePosition, rightHomebasePosition) {
		this.transcript.reset();
		this.transcript.saveDrillInfo("go-no-go", "0.2.0");

		this.questionTimer.start();
		this.hintTimer.start();

		this.transcript.saveHomeBase(
			Transcript.LEFT,
			leftHomebasePosition.left,
			leftHomebasePosition.top,
			"leftHome"
		);

		this.transcript.saveHomeBase(
			Transcript.RIGHT,
			rightHomebasePosition.left,
			rightHomebasePosition.top,
			"rightHome"
		);
	}

	targetShown(clientX, clientY) {
		const { x, rule } = this.getCurrent();

		const expectedTouch = !rule
			? Transcript.NO_GO
			: (x === "10%" ? Transcript.LEFT : Transcript.RIGHT);

		this.transcript.saveTarget(
			this.questionIndex,
			clientX,
			clientY,
			expectedTouch,
			this.questionTimer.ms(),
			"target"
		);
	}

	targetClicked(clientX, clientY) {
		if (!this.isLeftBaseHeld
			&& !this.isRightBaseHeld)
			return false;

		const { x } = this.getCurrent();

		if (x === "10%" && this.isLeftBaseHeld) {
			return false;
		}

		if (x === "70%" && this.isRightBaseHeld) {
			return false;
		}

		this.transcript.saveTouchStart(
			Transcript.DISMISSES_TARGET,
			this.questionIndex,
			clientX,
			clientY,
			this.questionTimer.ms()
		);

		return true;
	}

	rightBaseHeld(clientX, clientY) {
		this.isRightBaseHeld = true;
		this.resetBaseTimer();

		this.transcript.saveTouchStart(
			Transcript.ACTIVATES_HOME_BASE,
			Transcript.RIGHT,
			clientX,
			clientY,
			this.questionTimer.ms()
		);
		this.setState({ rightButtonHeld: true });
	}

	leftBaseHeld(clientX, clientY) {
		this.isLeftBaseHeld = true;
		this.resetBaseTimer();

		this.transcript.saveTouchStart(
			Transcript.ACTIVATES_HOME_BASE,
			Transcript.LEFT,
			clientX,
			clientY,
			this.questionTimer.ms()
		);
		this.setState({ leftButtonHeld: true });
	}

	resetBaseTimer() {
		this.baseTimer.clear();
		this.hintTimer.start();

		if (this.isRightBaseHeld && this.isLeftBaseHeld) {
			this.hideHint();
			this.baseTimer.start();
		}
	}

	hideHint() {
		this.setState({ showHint: false })
		this.hintTimer.clear();
	}

	checkHint() {
		if (this.hintTimer.ms() > hintTimeoutMs) {
			this.setState({ showHint: true })
		}
	}

	rightBaseReleased(clientX, clientY) {
		this.isRightBaseHeld = false;
		this.resetBaseTimer();

		this.transcript.saveTouchEnd(
			Transcript.DEACTIVATES_HOME_BASE,
			Transcript.RIGHT,
			clientX,
			clientY,
			this.questionTimer.ms()
		);
		this.setState({ rightButtonHeld: false });
	}

	leftBaseReleased(clientX, clientY) {
		this.isLeftBaseHeld = false;
		this.resetBaseTimer();

		this.transcript.saveTouchEnd(
			Transcript.DEACTIVATES_HOME_BASE,
			Transcript.LEFT,
			clientX,
			clientY,
			this.questionTimer.ms()
		);
		this.setState({ leftButtonHeld: false });
	}

	getCurrent() {
		return this.steps[this.questionIndex];
	}

	isTargetVisible() {
		const { rule } = this.getCurrent();
		const now = this.baseTimer.ms();

		return (now > this.targetDelay) && (now < this.targetDelay + redTargetMaxLifetime || rule);
	}

	autoProceedToNext() {
		const { rule } = this.getCurrent();

		return !rule && this.baseTimer.ms() > this.targetDelay + redTargetMaxLifetime;
	}

	isPaused() {
		return !this.isLeftBaseHeld || !this.isRightBaseHeld;
	}

	isFinished() {
		return this.questionIndex > this.steps.length - 1;
	}

	stop() {
		this.baseTimer.clear();
		this.questionTimer.clear();
	}
}
