import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import api from "../api";
import { Desktop } from "../components/Desktop";
import { fetchState, checkGetStateCalled } from '../globalState';

export const Login = () => {

    const history = useHistory();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        setError("");

        api.login.post({ email, password })
            .then(() => {
                fetchState().then(() => {
                    history.push("/d/home");
                });
            })
            .catch(err => setError(err.response.data));
    };

    useEffect(() => {
        checkGetStateCalled();
    }, []);

    return (
        <>
            <main className="main">
                <section className="form-section js-section section">
                    <div className="wrapper">
                        <form className="form">
                            <div className="form-inner">
                                <h2 className="form-title form-title--mb">
                                    Sign in to Your account
                                </h2>
                                <div class="form-wrap">
                                    <div class="form-brand"><img src="/images/logo.png" alt="" /></div>
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="email"
                                            id="email"
                                            placeholder="username"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="password"
                                            id="password"
                                            placeholder="password"
                                            value={password}
                                            onChange={e => setPassword(e.target.value)}
                                        />
                                    </div>
                                    {error && <div className="form-descr" style={{ marginTop: '10px', color: '#DC0019' }}>
                                        Error: {error}
                                    </div>}
                                    <div class="form-group form-group--btn">
                                        <button className="form-btn btn" onClick={(e) => handleSubmit(e)}>
                                            Login
                                        </button>
                                    </div>
                                </div>

                            </div>
                            <div className="form-links form-links--center form-bottom">Forgot your password?
                                <Link className="form-link form-link--center" to="/d/forgot-password">
                                    reset here
                                </Link>
                            </div>
                        </form>
                    </div>
                </section>
                <Desktop />
            </main>
        </>
    );
}
