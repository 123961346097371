
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Desktop } from "./Desktop";

export const AgeToLow = () => {
	useEffect(() => {
		global.screenHeight();
	}, []);

	return (
		<>
			<main className="main">
				<section className="completed js-section section changed">
					<div className="wrapper">
						<div className="completed-inner">
							<h2 className="completed-subtitle">
								SORRY, YOU MUST BE <br />
								<span>13 YEARS OF AGE OR OLDER</span><br />
								IN ORDER TO MAKE AN ACCOUNT
							</h2>
						</div>
					</div>
				</section>
				<Desktop />
			</main>
			<footer className="footer">
				<div className="wrapper">
					<div className="footer-btns">
						<Link className="footer-btn btn" to="/d/training">
							Go to training
						</Link>
					</div>
				</div>
			</footer>
		</>
	);
}